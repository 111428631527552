import * as React from 'react';
import PropTypes from 'prop-types';
import {  Grid, Card,RadioGroup, FormControlLabel, Radio, Typography, FormLabel, Button,TextField,FormControl,InputLabel,Select,MenuItem, OutlinedInput,InputAdornment,IconButton } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {  useSelector } from "react-redux";
import CompAutocomplete from "./CompAutocomplete";  
import CompMultiAutocomplete from "./CompMultiAutocomplete";  
import {  getImagePath } from '../../utils/Functions';

import defImage from "../../assets/images/defImage.jpg";



const CompFormContainer = (props) => {
 // console.log(props.field.value)
  
  const sizeWeb = props.field.value.length;
  const {defLang, defConfig} = useSelector((state) => state.Login);  
  var maxSize=0
  var  columns=[]
  for (let i in props.field.value){
  	
  	let tmpSize=props.field.value[i].length
  	if (tmpSize>maxSize){
  		maxSize= tmpSize
  	}
  }
  for (let j=0; j<maxSize; j++ ){
  	for (let i in props.field.value){
  		
  		var xs = 12
  		if ( sizeWeb <=2){
				xs = 12/sizeWeb
			}else{
				xs=6
			}
  		var lg = 12/sizeWeb
  		
  		if (props.field.value[i][j] ){
  			if (props.field.value[i][j].size.xs!="auto"){
  				xs = props.field.value[i][j].size.xs
  			}  			
  			if (props.field.value[i][j].size.lg!="auto"){
  				lg = props.field.value[i][j].size.lg
  			}
  			columns.push({
  				name    : props.field.value[i][j].name,
  				label   : props.field.value[i][j].label,
  				type    : props.field.value[i][j].type,
  				size    : {xs    : xs,
  					    		 lg    : lg},
  				values  : props.field.value[i][j].values,
  				originalvalues: props.field.value[i][j].values,
  				value   : props.field.value[i][j].value,
  				variant : props.field.value[i][j].variant,
  				color   : props.field.value[i][j].color,
  			})
  		}else{
  			columns.push({
  				name  : "space_"+i+"_"+j,
  				label : "",
  				type  : "space",
  				size  : {xs    : xs,
  							   lg    : lg}
  			})
  		}
  	}
  }
  const [showPasswords, setShowPasswords] = React.useState({})
  const [tmpvalue, setTmpvalue] = React.useState([]);
  
  const handleClickShowPassword = (field) => {
  	let _showPasswords = showPasswords
  	if (_showPasswords[field]){
  		_showPasswords[field]=false
  	}else{
  		_showPasswords[field]=true
  	}
    setShowPasswords(_showPasswords);
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const tmphandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTmpvalue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  return (
   
  	<Card  sx={{ border: "1px solid", borderColor:"success.main"}}>
      <Grid mb={2} container p={2} spacing={1}>
      	<Grid item xs={12} lg={12}>
      		<Typography variant="h6" color="success.main">
          	{defConfig[defLang].previewlabel}
         	</Typography>
      	</Grid>
      	
	      	<>
	      	{ (props.field.loaded? props.field.formattedvalue : columns).map((field, index) => {
						return( field.type!="-" &&
	      			<Grid key={"Griditem"+index} item xs={field.size.xs} lg={field.size.lg}>
	      				{!field.hide && field.type == "title" && 
	      					<>
									<Typography key={"formtitle"+index} variant={field.variant}  color={field.color+".main"}>
		              	{field.label}
		              	
		              	<Typography key={"formtitle2"+index} variant="caption" >
		              	{" ("+field.name+")["+index+"]"}
			           		</Typography>
			           	
			           	</Typography>
			           	
			           	
			           	
			           	
			           	</>
	              }
	              
	              {!field.hide && field.type == "hidden" && 
	      					<>
									<Typography key={"formtitle"+index} variant="body"  color={field.color+".main"}>
		              	{field.label} 
		              	<Typography key={"formtitle2"+index} variant="caption" >
		              	{" ("+field.name+")["+index+"]"}
			           		</Typography> 
			           	</Typography>
			           	
			           	
			           	
			           	
			           	</>
	              }
	                
	              {!field.hide && field.type == "date" && 
	              	<TextField
	                 	key={"formdate"+index}
	                  fullWidth
	                  label={field.label +"("+field.name+")["+index+"]"}
	                  disabled={field.disabled}
	                />
	              }
	              
	              {!field.hide && field.type == "staticimage" &&  
	              	<>
	              		<FormLabel key={"formcontrolrhl"+index} >{field.label+" ("+field.name+")["+index+"]"}</FormLabel>
				           	<img 
													key={"img"+index} 
													name={"selectItem_"+index}  
													style={{width:"100%", cursor:"pointer" }} 
													src={defImage}  
										/>
									</>
	           		}
	              
	              {!field.hide && field.type == "image" && 
	        	 			<>
	        	 				
				           	<img 
													key={"img"+index} 
													name={"selectItem_"+index}  
													style={{width:"100%", cursor:"pointer" }} 
													src={defImage}  
										/>
										
										<Button
			                type="button"
			                disabled={field.disabled}
			                variant="contained"
			                color="info"
			                onClick={ ()=>{ /*setMultipleImages(false); setOpenFileManagerDialog(true) ; setFieldName(field.name)*/ }}
			                sx={{ mt:"-79px", ml:"11px", textTransform:"initial", fontWeight: {md:"normal"}    }}
			            	>  Cambiar Imagen 
			            	</Button>
		           		</>
	           		}
			                 		
	            	{!field.hide && field.type == "text" && 
	                 <TextField
	                 	key={"formtext"+index}
	                  fullWidth
	                  value={field.value}
	                  disabled={field.disabled}
	                 
	                  label={field.label+" ("+field.name+")["+index+"]"}
	                />
	              }
	              
	              {!field.hide && field.type == "radiobuttonh" && 
	                 <FormControl key={"formcontrolrh"+index}>
							      <FormLabel key={"formcontrolrhl"+index} >{field.label+" ("+field.name+")["+index+"]"}</FormLabel>
							      <RadioGroup
							     	  key={"formcontrolrhrg"+index}
							      	row
							      >
							        {field.values && field.values.map((value, index2) => {
									    return(
									    	<FormControlLabel key={"formcontrolrh"+index+index2} value={value.value} control={<Radio />} label={value.text} />
									    )
	         						})}
							      </RadioGroup>
							    </FormControl>
	              }
	              
	              {!field.hide && field.type == "radiobuttonv" && 
	                 <FormControl key={"formcontrolrv"+index}>
							      <FormLabel key={"formcontrolrvl"+index} >{field.label+" ("+field.name+")["+index+"]"}</FormLabel>
							      <RadioGroup
							     	  key={"formcontrolrvrg"+index}
							        
							      	row
							      >
							        {field.values && field.values.map((value, index2) => {
									    return(
									    	<FormControlLabel key={"formcontrolrv"+index+index2} value={value.value} control={<Radio />} label={value.text} />
									    )
	         						})}
							      </RadioGroup>
							    </FormControl>
	              }
	              
	              {!field.hide && field.type == "password" && 
	                <FormControl fullWidth variant="outlined" >
					          <InputLabel htmlFor="password">{field.label+" ("+field.name+")["+index+"]"}</InputLabel>
					          <OutlinedInput
					          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
	                    value={field.value} 
					            type={showPasswords[field.name] ? 'text' : 'password'}
					            endAdornment={
					              <InputAdornment position="end">
					                <IconButton
					                  aria-label="toggle password visibility"
					                  onClick={() =>{ handleClickShowPassword(field.name) }}
					                	onMouseDown={()=>{ handleMouseDownPassword(field.name) }}
					                  edge="end"
					                >
					                  {showPasswords[field.name] ? <LockOpenIcon/> : <LockIcon />}
					                </IconButton>
					              </InputAdornment>
					            } 
					            label={field.label}
					      		/> 
					        </FormControl>
	              }
	              
	              {!field.hide && field.type == "textarea" && 
	                 <TextField
	                 	key={"formtextarea"+index}
	                  fullWidth
	                  multiline
	                  value={field.value}
	                  disabled={field.disabled}
	                  rows={2}
	                  label={field.label+" ("+field.name+")["+index+"]"}
	                />
	              }
	              {!field.hide && field.type == "autocomplete" && 
	                	<CompAutocomplete disabled={field.disabled} handleChange={()=>{ }} key={"formautocomplite"+index} field={field}  />
	              }
	              
	              {!field.hide && field.type == "multipleautocomplete" && 
	                	<CompMultiAutocomplete disabled={field.disabled} handleChange={()=>{ }} key={"multipleautocomplete"+index} field={field}  />
	              }
	              
	              {!field.hide && field.type == "select" && 
	                 <FormControl fullWidth>
									  <InputLabel>{field.label+" ("+field.name+")["+index+"]"} </InputLabel>
									  <Select
									    key={"formselect"+index} 
									    disabled={field.disabled}
									    label={field.label} 
									    value={field.value}
									  >
									  	{field.values && field.values.map((value, index2) => {
									    return(
									    	<MenuItem key={"formselect"+index+index2}   value={value.value}>{value.text}</MenuItem>
									    )
	         						})}
									  </Select>
									</FormControl>
	              }
	              
	               {!field.hide && field.type == "multiple" && 
	                 <FormControl fullWidth>
									  <InputLabel>{field.label+" ("+field.name+")["+index+"]"} </InputLabel>
									  <Select
									  	multiple
									  	disabled={field.disabled}
									  	value={tmpvalue}
									  	onChange={tmphandleChange}
									    key={"formmultiple"+index} 
									    label={field.label} 
									    
									  >
									  	{field.values && field.values.map((value, index2) => {
									    return(
									    	<MenuItem key={"formmultiple"+index+index2}  value={value.value}>{value.text}</MenuItem>
									    )
	         						})}
									  </Select>
									</FormControl>
	              }
	      				
	            </Grid>
			    	)
			  	})}
		  		</>
		  	 
		  	
    	</Grid>  
    </Card> 
  
  );
};

CompFormContainer.propTypes = {};

export default CompFormContainer;

