export default {
 
  excludeSideBar: ["/", "/home", "/login"],
  //ProfileMenus: [ "Perfil", "Administaci\u00F3n", "Salir"],
  
  countryList :[
  "Bolivia",
  "Afganist\u00E1n",
	"Albania",
	"Alemania",
	"Andorra",
	"Angola",
	"Anguila",
	"Ant\u00E1rtida",
	"Antigua y Barbuda",
	"Antillas holandesas",
	"Arabia Saud\u00ED",
	"Argelia",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaiy\u00E1n",
	"Bahamas",
	"Bahrein",
	"Bangladesh",
	"Barbados",
	"B\u00E9lgica",
	"Belice",
	"Ben\u00EDn",
	"Bermudas",
	"Bhut\u00E1n",
	"Bielorrusia",
	"Birmania",
	"Bosnia y Herzegovina",
	"Botsuana",
	"Brasil",
	"Brunei",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Camboya",
	"Camer\u00FAn",
	"Canad\u00E1",
	"Chad",
	"Chile",
	"China",
	"Chipre",
	"Ciudad estado del Vaticano",
	"Colombia",
	"Comores",
	"Congo",
	"Corea",
	"Corea del Norte",
	"Costa del Marf\u00EDl",
	"Costa Rica",
	"Croacia",
	"Cuba",
	"Dinamarca",
	"Djibouri",
	"Dominica",
	"Ecuador",
	"Egipto",
	"El Salvador",
	"Emiratos Arabes Unidos",
	"Eritrea",
	"Eslovaquia",
	"Eslovenia",
	"España",
	"Estados Unidos",
	"Estonia",
	"c",
	"Ex-Rep\u00FAblica Yugoslava de Macedonia",
	"Filipinas",
	"Finlandia",
	"Francia",
	"Gab\u00F3n",
	"Gambia",
	"Georgia",
	"Georgia del Sur y las islas Sandwich del Sur",
	"Ghana",
	"Gibraltar",
	"Granada",
	"Grecia",
	"Groenlandia",
	"Guadalupe",
	"Guam",
	"Guatemala",
	"Guayana",
	"Guayana francesa",
	"Guinea",
	"Guinea Ecuatorial",
	"Guinea-Bissau",
	"Hait\u00ED",
	"Holanda",
	"Honduras",
	"Hong Kong R. A. E",
	"Hungr\u00EDa",
	"India",
	"Indonesia",
	"Irak",
	"Ir\u00E1n",
	"Irlanda",
	"Isla Bouvet",
	"Isla Christmas",
	"Isla Heard e Islas McDonald",
	"Islandia",
	"Islas Caim\u00E1n",
	"Islas Cook",
	"Islas de Cocos o Keeling",
	"Islas Faroe",
	"Islas Fiyi",
	"Islas Malvinas Islas Falkland",
	"Islas Marianas del norte",
	"Islas Marshall",
	"Islas menores de Estados Unidos",
	"Islas Palau",
	"Islas Salom\u00F3n",
	"Islas Tokelau",
	"Islas Turks y Caicos",
	"Islas V\u00EDrgenes EE.UU.",
	"Islas V\u00EDrgenes Reino Unido",
	"Israel",
	"Italia",
	"Jamaica",
	"Jap\u00F3n",
	"Jordania",
	"Kazajist\u00E1n",
	"Kenia",
	"Kirguizist\u00E1n",
	"Kiribati",
	"Kuwait",
	"Laos",
	"Lesoto",
	"Letonia",
	"L\u00EDbano",
	"Liberia",
	"Libia",
	"Liechtenstein",
	"Lituania",
	"Luxemburgo",
	"Macao R. A. E",
	"Madagascar",
	"Malasia",
	"Malawi",
	"Maldivas",
	"Mal\u00ED",
	"Malta",
	"Marruecos",
	"Martinica",
	"Mauricio",
	"Mauritania",
	"Mayotte",
	"M\u00E9xico",
	"Micronesia",
	"Moldavia",
	"M\u00F3naco",
	"Mongolia",
	"Montserrat",
	"Mozambique",
	"Namibia",
	"Nauru",
	"Nepal",
	"Nicaragua",
	"N\u00EDger",
	"Nigeria",
	"Niue",
	"Norfolk",
	"Noruega",
	"Nueva Caledonia",
	"Nueva Zelanda",
	"Om\u00E1n",
	"Panam\u00E1",
	"Papua Nueva Guinea",
	"Paquist\u00E1n",
	"Paraguay",
	"Per\u00FA",
	"Pitcairn",
	"Polinesia francesa",
	"Polonia",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Reino Unido",
	"Rep\u00FAblica Centroafricana",
	"Rep\u00FAblica Checa",
	"Rep\u00FAblica de Sud\u00E1frica",
	"Rep\u00FAblica Democr\u00E1tica del Congo Zaire",
	"Rep\u00FAblica Dominicana",
	"Reuni\u00F3n",
	"Ruanda",
	"Rumania",
	"Rusia",
	"Samoa",
	"Samoa occidental",
	"San Kitts y Nevis",
	"San Marino",
	"San Pierre y Miquelon",
	"San Vicente e Islas Granadinas",
	"Santa Helena",
	"Santa Luc\u00EDa",
	"Santo Tom\u00E9 y Pr\u00EDncipe",
	"Senegal",
	"Serbia y Montenegro",
	"Sychelles",
	"Sierra Leona",
	"Singapur",
	"Siria",
	"Somalia",
	"Sri Lanka",
	"Suazilandia",
	"Sud\u00E1n",
	"Suecia",
	"Suiza",
	"Surinam",
	"Svalbard",
	"Tailandia",
	"Taiw\u00E1n",
	"Tanzania",
	"Tayikist\u00E1n",
	"Territorios brit\u00E1nicos del oc\u00E9ano Indico",
	"Territorios franceses del sur",
	"Timor Oriental",
	"Togo",
	"Tonga",
	"Trinidad y Tobago",
	"T\u00FAnez",
	"Turkmenist\u00E1n",
	"Turqu\u00EDa",
	"Tuvalu",
	"Ucrania",
	"Uganda",
	"Uruguay",
	"Uzbekist\u00E1n",
	"Vanuatu",
	"Venezuela",
	"Vietnam",
	"Wallis y Futuna",
	"Yemen",
	"Zambia",
	"Zimbabue"
  ]
};
