import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
 import "./Loading.css";
const loadMessage = props => {
 
  return ( 
   
   	<>
   		<div  className="loadingBG">
		 	</div>
			<div  className="loadingBG2">
			  
			      <CircularProgress />
			   
			 
			</div>
		</> 
  );
	 
};
export default loadMessage;
				 