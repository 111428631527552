import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Container,
  Typography,
  FormGroup,
  Grid,
  Card,
  Button, 
  IconButton,
  Popover ,
  TextField,
  Input,
  InputAdornment,
  Radio ,
  RadioGroup ,
  FormControlLabel,
  Dialog
} from "@mui/material";
import Loading from "../../components/Loading";
import FileManagerDialog from "./FileManagerDialog";
import CompDragFormItem from "../dnd/CompDragFormItem";
import styled from '@emotion/styled';
import { find, getImagePath} from '../../utils/Functions';
import { useSelector } from "react-redux";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/*
const Container = styled.div`
  display: flex;
`;*/

const ColumnInputs = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background:#fff;
  /*min-width: 300px;*/
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 0px 10px;
  margin :7px;
`;

const ColumnStyle = styled.div`
  margin: 5px;
  display: flex;
  width: 100%;
  min-height: 60vh;
  background: #f1f1f1;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
`;

const columnsFromBackend = [
  	[
		] 
	];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
	return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
 
const ComponentFormContainerDialog = (props) =>  {

	const {defLang, defConfig} = useSelector((state) => state.Login);	
	
	const [columns, setColumns] = useState(columnsFromBackend);

  const onDragEnd = (result) => {
  	const { source, destination } = result;
  	// dropped outside the list
    if (!destination) {
      return;
    }
    
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
 
    if (sInd === dInd) {
    	 
      const items = reorder(columns[sInd], source.index, destination.index);
      const newColumns = [...columns];
      newColumns[sInd] = items;
      setColumns(newColumns);
    } else {
      const result = move(columns[sInd], columns[dInd], source, destination);
      const newState = [...columns];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setColumns(newState.filter(group => group.length));
    }
  };
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
 
	const [isFaching,setIsFatching]    = useState(false)
	const [values,setValues]    = useState([])
	
	//for image manager
	const [openFileManagerDialog, setOpenFileManagerDialog] = React.useState(false);
	const [fieldName, setFieldName] = React.useState("");
	
  React.useEffect(()=>{
  	
  	
  	setIsFatching(true);
    setTimeout(() => {
        setColumns(props.value)
        setIsFatching(false);
    }, 500);
  },[]) 
   
	const deleteItem = (event) => {
  	
  	let field = event.split("_")
  	let posCol = parseInt(field[0])
  	let itemid =  parseInt(field[1])  	
  	let _columns = columns
  	let _column = columns[posCol]  
  	let posToDelete = find(_column, itemid, "id" )  	
  	_column.splice( posToDelete,1 )
  	let finalevent = { target: {name: props.fieldName, value:_columns, type:"form" }}
  	props.handleChange(finalevent)
  	
  }
	
  const addNewValue = (event) => {
   
  	let _columns = columns
  	let lastPos = columns.length-1
  	let _column = columns[lastPos]
  	let newId=0
  	
  	_columns.map(function (col) {
  		col.map(function (key) {
	  		if (newId< key.id){
	  			newId=key.id
	  		}
	  	}); 
  	}); 
  	//console.log(newId)
  	newId++
  	let newItem ={
  			id: newId,
	  		name: "",
	  		type: "text",
	  		label: "",
	  		action:"",
	  		validations: {
	  			required : {status: false, message:""},
		    	string   : {status: false, message:""},
		    	number   : {status: false, message:""},
		    	email    : {status: false, message:""},
		    	min      : {status: false, message:"", min:"" },
		    	max      : {status: false, message:"", max:"" }
	  		},
	  		value:"",
	  		values: []  ,
	  		variant:"",
	  		color:"primary",
  			apiurl:    "",
  			apimethod: "POST",
  			apidefdata:"",
  			apitoken:  "",
  			size:{
  				xs:"auto",
  				lg:"auto"
  			}

	  		 
		  }
  	_column.push(newItem)
  	let finalevent = { target: {name: props.fieldName, value:_columns, type:"form" }}
  	props.handleChange(finalevent)
  };
	
	const handleChange = (colIndex, id,  fieldname,fieldvalue ) => {

		let _columns = columns		
		let _column = _columns[colIndex ]
		let pos = find (_column, parseInt(id), "id")	
		_column[pos][fieldname] = fieldvalue
		setColumns(_columns)
		
		//console.log (colIndex, id,  fieldname,fieldvalue ) 
		let finalevent = { target: {name: props.fieldName, value:_columns, type:"form" }}
		if (fieldname=="size" || fieldname == "validations" || fieldname == "values" ){
			props.handleChange(finalevent)	
		}
	};
  return (
    <Dialog fullWidth={true}    scroll="paper" TransitionComponent={Transition} open={props.open} onClose={props.onClose}   fullScreen={true} >
     	{isFaching ? <Loading />  : ""}
     	<Grid container maxWidth="xl" sx={{ mt: 2, mb: 3 }}>
     		<Grid item xs={11} pl={3}  >
	              	<Typography variant="h5"
			            	 sx={{ 
		                  fontFamily: "'Open Sans', sans-serif",
		                  fontWeight: "bolder",
		                  textTransform: "none",

		                }}>
		               Crear Formulario
			           	</Typography>  
	              </Grid>
      	<Grid item xs={1} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
					  <CloseIcon />
					</IconButton>
      	</Grid>
      	<Grid item xs={12} >
        	<Card>
          	<Container style={{ marginTop: 20, marginBottom: 20  }}>
          		<Grid container spacing={1}>
					     	<DragDropContext onDragEnd={onDragEnd} >
						      <Container>
						        <ColumnStyle>
						          {columns.map((column, index) => {
						            return (
						            	<Droppable key={"Droppable"+index} droppableId={`${index}`}>

						                {(provided, snapshot) => (
						                  <ColumnInputs
							                  key={"ColumnInputs"+index}
							                  style={{width: ((100/(columns).length)-2)+"%"  }}
						                    ref={provided.innerRef}
						                    {...provided.droppableProps}
						                  >
						                    
						                    {column.map((item, index2) => {
						                    	return(
						                      	<CompDragFormItem key={"itemfield"+index+index2} item={item} index={index2} deleteItem={deleteItem} masterhandleChange={handleChange} mastervalue={index} />
						                    	);
						         						})}
						                    {provided.placeholder}
						                  </ColumnInputs>
						                )} 
						              </Droppable>
						            );
						          })}
						        </ColumnStyle>
						      </Container>
						    </DragDropContext>
					    
					   	 <Grid item xs={12} sx={{textAlign: "right"}} >
				      		<Button
		                type="button"
		                variant="contained"
		                color="secondary"
		                onClick={  addNewValue }
		                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
		            	> {defConfig[defLang].addfieldlabel}
		            	</Button>
				      	 	{columns.length<4 &&
					      		<Button
			                type="button"
			                variant="contained"
			                color="primary"
			                onClick={() => {
			                	if (columns.length<4){
			                		setColumns([...columns, []]);	
			                	}
							          
							        }}
			                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}  , ml:1  }}
			            	> {defConfig[defLang].addcolumnlabel}
			            	</Button>
			            }
				      	</Grid>
            	
            	
	           	</Grid> 
						</Container>
          </Card>
        </Grid>   	
        <Grid item xs={6} pl={2}  pt={2} >
        	<Typography variant="body2" >
		      	(*) {defConfig[defLang].maxsizemobilemessage}
			    </Typography>
       	</Grid> 
        <Grid item xs={6} sx={{textAlign: "right"}} pr={2} pt={2}  >
      		<Button
            type="button"
            variant="contained"
            color="success"
            onClick={ ()=>{
            	let finalevent = { target: {name: props.fieldName, value:columns, type:"form" }}
  						props.handleChange(finalevent);
  						props.onClose() }}
            sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
        	> {defConfig[defLang].savelabel}
        	</Button>
      	</Grid>
		    
	    </Grid>
  </Dialog>
  );
};

ComponentFormContainerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ComponentFormContainerDialog;
