import * as React from 'react';
import { 
	Container,
	Grid,
	Button,
	IconButton,
	Menu,
	MenuItem
} from "@mui/material"; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
import BlockIcon from '@mui/icons-material/Block';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import { findObjName,getCookie,setCookie, getImagePath} from '../../utils/Functions';
import {useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';	
import RefreshIcon from '@mui/icons-material/Refresh';
import PreviewIcon from '@mui/icons-material/Preview';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector, useDispatch } from "react-redux";
/*DIALOG COMPONENTS*/
import DeleteItemDialog from "../dialog/DeleteItemDialog";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const StyledTable = (props) => {
	console.log(props)
	const [page, setPage] = React.useState(0);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const { defConfig,  defLang } = useSelector((state) => state.Login);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
	
	const [anchorEl, setAnchorEl] = React.useState (null);
	const [id,setId]    = React.useState(0)
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event ) => {
 	var id = findObjName(event) 

 	setId(id)
  setAnchorEl(event.target);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  
  return (
  <>
  	<DeleteItemDialog componentName={props.componentName} id={id} open={openDeleteDialog}  onClose={()=>{ setOpenDeleteDialog(false) } } />
      
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
         		<>
         			<TableCell
                  key={0}
                  align="left"
                  style={{ minWidth:50 }}
                >
                 {defConfig[defLang].actionslabel}
              </TableCell>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth?column.minWidth:100 }}
                >
                  {column.label}
                </TableCell>
              ))}
          	</>
        	</TableRow>
        </TableHead>
        <TableBody>
        
        	{props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={"tablerow"+index}>
              	<> {/*startIcon={<MenuIcon />} */}	
									<TableCell key={"tablecell"+index} component="th" scope="row">
										{props.writable?
			              	<IconButton 
			              	key={"rowIconButton"+index}
			              	size="large"
												        id= {row[props.columns[0].id]}
												        name= {row[props.columns[0].id]}
												        key= {row[props.columns[0].id]}
												        aria-controls={open ? 'demo-customized-menu' : undefined}
												        aria-haspopup="true"
												        aria-expanded={open ? 'true' : undefined}
												        variant="contained"
												        disableElevation
												        
												        style={{ 
												        	fontSize: 14,
																	padding: 8,
																	background: '#fff',
																	color: '#1976d2',
																	border: '1px solid #1976d2',
																	lineHeight: 1,
																	borderRadius: 4
												        }}
												        
												        onClick={handleClick}
												        endIcon={<KeyboardArrowDownIcon />}
												      >
												        <MenuIcon fontSize="inherit"/>
												        <KeyboardArrowDownIcon fontSize="inherit"/>
											</IconButton>
										:
											<IconButton 
			              	key={"rowIconButton"+index}
			              	size="large"												         
												        key= {row[props.columns[0].id]}
												        variant="contained"
												        disableElevation												        
												        style={{ 
												        	fontSize: 14,
																	padding: 8, 
																	lineHeight: 1,
																	borderRadius: 4
												        }} 
												        endIcon={<KeyboardArrowDownIcon />}
												      >
												        <BlockIcon color="error" fontSize="inherit"/>
												        
											</IconButton>
										}
		              </TableCell>
	                {props.columns.map((column, indexCell) => {
	                  const value = row[column.id];
	                  return (
	                    <TableCell key={"TableCell2"+indexCell} key={column.id} align={column.align}>
	                      {column.format && typeof value === 'number'
	                        ? column.format(value)
	                        : column.format === 'image'
	                        	? <img 
																		width={48}
																		height={48}
																		src={getImagePath(value)} 
															/>
	                        : 
	                        	column.format=="date"?
                							new Date(value).toLocaleDateString(defLang, { weekday:"short", year:"numeric", month:"short", day:"numeric" /*, hour: "numeric", minute:"numeric" */})
                						: 
                							value
	                       }
	                    </TableCell>
	                  );
	                })}
                </>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorOrigin={{
		    vertical: 'bottom',
		    horizontal: 'left',
		  }}
		  transformOrigin={{
		    vertical: 'top',
		    horizontal: 'left',
		  }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}>
      
      {props.componentOptions.indexOf("update")>=0 &&
	      <MenuItem onClick={() => { navigate("/manage/"+props.componentName+"/update/"+id); }}>
	        <EditIcon />{defConfig[defLang].editlabel}
	      </MenuItem>
	    }
	    {props.componentOptions.indexOf("preview")>=0 &&
	      <MenuItem disableRipple onClick={() => { navigate("/manage/"+props.componentName+"/preview/"+id); }}>
	        <PreviewIcon />
	        <span> {defConfig[defLang].previewlabel} </span>
	      </MenuItem>
	    }  
	    {props.componentOptions.indexOf("refresh")>=0 &&
	      <MenuItem disableRipple>
	        <RefreshIcon />
	        <span> {defConfig[defLang].refreshlabel} </span>
	      </MenuItem>
	    }
	    {props.componentOptions.indexOf("delete")>=0 &&
	      <MenuItem disableRipple  onClick={()=> { setOpenDeleteDialog(true) ; setAnchorEl(null); }} >
	        <DeleteForeverIcon style={{color: '#DE2C27'}} />
	        <span style={{color: '#DE2C27'}}> {defConfig[defLang].deleteItemlabel} </span>
	      </MenuItem>
      }
    </StyledMenu>
    </>
  );
};

StyledTable.propTypes = {};

export default StyledTable;