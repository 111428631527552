import * as React from 'react';
import PropTypes from 'prop-types';
import {  Grid, Card,Typography,Button } from "@mui/material";
 
import {  getImagePath } from '../../utils/Functions';

const CompSliderBanner = (props) => {
    
  return (
    props.field.value.map((mastervalue, index) => {
		return(
			
			<Grid item xs={12} >
				<Card>
					<Grid container p={2} spacing={1}>
					
						{mastervalue.childs.map((value, index) => {
							return(
								<Grid item xs={12} md={12/mastervalue.childs.length} lg={12/mastervalue.childs.length }>
									<Grid container   sx={{ pb:1, pr:1} } spacing={1}>
									
										<Grid item xs={12}>
											<Typography variant="h4"
													sx={{ 
												fontFamily: "'Open Sans', sans-serif",
												fontWeight: "bolder",
												textTransform: "none",

											}}>
											{value.title}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="h5"
													sx={{ 
												fontFamily: "'Open Sans', sans-serif",
												fontWeight: "bolder",
												textTransform: "none",

											}}>
											{value.subtitle}
											</Typography>
										</Grid>	 
										<Grid item xs={12}>
												<Typography variant="body2"
													sx={{ 
												fontFamily: "'Open Sans', sans-serif",
												fontWeight: "bolder",
												textTransform: "none",

											}}>
											{value.body}
											</Typography>
										</Grid>	 
										{value.button!==""&&
											<Grid item xs={12}>
												<Button
												type="button" 
												variant="contained"
												color="primary"
												size="small"
												sx={{   textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
												> {value.button+ "("+ value.link+")"}
												</Button>
											</Grid>	 
										}
									
										{value.imagepath!=="" &&
											<Grid item xs={12}>
												<img 
																	key={"imagepath"+index} 
																	name={"imagepath_"+index}  
																	style={{maxHeight: 250, width:"100%", cursor:"pointer" }}
																		
																	src={getImagePath(value.imagepath)} 
																	alt={value.imagepath}
														/>
											</Grid>
										}
										
									</Grid>
								</Grid>
							)
						})}  
					</Grid>  
				</Card> 
			</Grid>
		)
	})
    
  );
};

CompSliderBanner.propTypes = {};

export default CompSliderBanner;

