import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import { find} from '../../utils/Functions'; 

const CompAutocomplete = (props) => {

	const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');
     
  const [isFaching,setIsFatching]    = React.useState(false)
  
  React.useEffect(() => {
 		//console.log(props)
 		if(props.field.value+"" =="" ){
 			setIsFatching(false);
 		}else{
 			setIsFatching(true); 				
 			if (props.field.values.length>0 && (props.field.value+"").length>0 ){
 				let pos = find ( props.field.values, props.field.value+"", "value")
		  	setValue(props.field.values[pos])
		  	setInputValue(props.field.values[pos].text)
		  }
	 		setTimeout(() => {
	       setIsFatching(false);
	    }, 200);
 		}
  }, [props]);

  if (!isFaching){
	  return (
	  	<Autocomplete
	  			disabled={props.disabled}
	        value={value}
	        onChange={(event, newValue) => {
	          setValue(newValue);
	          props.handleChange( {target: {name: props.field.name, value: newValue.value } }  );
	        }}
	        inputValue={inputValue}
	        onInputChange={(event, newInputValue) => {
	          setInputValue(newInputValue);
	        }}
	        getOptionLabel={(option) => option.text}
	        id="controllable-states-demo"
	        options={props.field.values}
	        sx={{ width: '100%' }}
	        renderInput={(params) => <TextField {...params} label={props.field.label}/>}/>
	  );
  }else{
    return ('');	
  }
};

CompAutocomplete.propTypes = {};

export default CompAutocomplete;

