import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";

import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import Dashboard from "../pages/dashboard/Dashboard";

import ListData from "../pages/admin/ListData";
import RepData from "../pages/admin/RepData";

 
import ManageComponent from "../pages/admin/ManageComponent";



import ApiClient  from '../api/ApiClient';
import {getUserRol} from '../utils/Functions';
import { useDispatch, useSelector } from "react-redux";
const Routes = (props) => {
  const {
    
    token,
    defLang
  } = props;
    
  const routes = [
    {
      path: "/",
      element: <MainLayout   /> ,
      children: [
        {
          path: "/",
          element:   <Navigate to={"/dashboard"} />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/manage/:componentName/:action/:id",
          exact:true,
          element: <ManageComponent />,
        },
        {
          path: "/manage/:componentName",
          exact:true,
          element: <ListData />,
        },

        {
          path: "/report/:componentName",
          exact:true,
          element: <RepData />,
        },
         
      ],
    },
  ];

  const router = useRoutes(routes);

  return <>{router}</>;
};

export default Routes;
