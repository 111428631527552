import actionTypes from "../action.types";
import {getUserRol,getCookie,setCookie, createValidationSchema } from '../../utils/Functions';
import { toast } from "react-toastify";
var defLang = process.env.REACT_APP_DEF_LANGUAGE
const initialState = {  
  processSuccess:false,
  processFailed:false,
  processMessage:"",
  processError:"" ,
  proccessname:"initial",
  requesting:false,
  formFields:[],
  formConfig:{initial:true},
  initialValues:{},
  validationSchema:{},
  componentConfig:{initial:true},
  reloadPage:false,
  defLang: defLang?defLang:process.env.REACT_APP_DEF_LANGUAGE,  
  loadid: 0,
};



const ProcessReducer =   (state = initialState, action) => {
	
  const { type, payload } = action;
  switch (type) {
  	case actionTypes.CLEAR_MESSAGE:
      return {
        ...state, 
        processMessage:"",
        processError:""
      };
    case actionTypes.PROCESS_REQUEST:
    	var token = getCookie(process.env.REACT_APP_NAME+'_usertoken')
    	var username = getCookie(process.env.REACT_APP_NAME+'_username')
    	
    	var reloadPage=false
    	if (!token){
    		reloadPage=true
    	}
    	
    	setCookie(process.env.REACT_APP_NAME+'_usertoken', token,  process.env.REACT_APP_SESSION_DURATION);
    	setCookie(process.env.REACT_APP_NAME+'_username', username,  process.env.REACT_APP_SESSION_DURATION);
      return {
        ...state,
        proccessname: payload.proccessname,
        requesting: true,
        reloadPage: reloadPage,
        processSuccess: false,
        processFailed: false,
        processMessage:"",
        processError:"",
      //  componentConfig:{initial:true}, //check
        //formFields:[]
      };
    
		case actionTypes.PROCESS_RESET:
    
      return {
        ...state,
        processSuccess: false,
        processMessage: "",
      };
      
    case actionTypes.PROCESS_SUCCESS:
    	
      
    	toast.success(payload.message?payload.message:"Procesado correctamente");	
    	if (payload.componentConfig){
    		return {
	        ...state,
	        requesting: false,
	        processSuccess: true,
	        processMessage: payload.message?payload.message:"Procesado correctamente",
	        componentConfig:payload.componentConfig
	      };
    	}else{
    		return {
	        ...state,
	        requesting: false,
	        processSuccess: true,
	        processMessage: payload.message?payload.message:"Procesado correctamente"
	      };
    	}
    
    case actionTypes.BULK_PROCESS_SUCCESS:
    	toast.success(payload.message);	     
    		return {
	        ...state,
	        requesting: false,
	        processSuccess: true,
          loadid : payload.id,
	        processMessage: payload.message?payload.message:"Procesado correctamente"
	      };
    	 

    case actionTypes.PROCESS_FAILED:
    	toast.error(payload.message);
      return {
        ...state,
        requesting: false,
        processFailed: true,
        processSuccess: false,
        processError: payload.message,
        loadid:0
      };
    case actionTypes.PROCESS_ERROR:
      return {
        ...state,
        requesting: false,
        processFailed: true,
        processSuccess: false,
        processError: payload.error?payload.error:payload.message,
        loadid:0
      };
    case actionTypes.FORM_LOADED:
      
      let validationSchema =  createValidationSchema (payload.formData )
      let initialValues={}
	  	payload.formData.map(function (key, item) {
			 	initialValues[ key.name ] = key.value
			}); 
      return {
        ...state,
        requesting: false, 
        formFields: payload.formData,
        //componentConfig:payload.componentConfig,
        initialValues:initialValues,
        validationSchema: validationSchema,
        processSuccess: false,
      }; 
    case actionTypes.FORM_ERROR:
     
      return {
        ...state,
        requesting: false, 
        formFields: [],
        initialValues:{},
        validationSchema: {},
        processSuccess: false,
      };   
		case actionTypes.CLEAR_FORM:
   		return {
        ...state, 
        formFields: []
      };   
    case actionTypes.COMPONENT_LOADED:
  
    	return {
        ...state,
        requesting: false, 
        formFields: [],
        initialValues:{},
        validationSchema: {},
        componentConfig:payload,
        processSuccess: false,
      };   
    case actionTypes.COMPONENT_ERROR:

    	return {
        ...state,
        requesting: false, 
        formFields: [],
        initialValues:{},
        validationSchema: {},
        componentConfig:{error:true},
        processSuccess: false,
      };   
    default:
      return state;
  }
};

export default ProcessReducer;
