/* eslint-disable import/no-anonymous-default-export */
export default {
    login: '/auth/login',    
    getDataByCodeLanguage :"/params/getDataByCodeLanguage",
    getDataByDomainLanguage :"/params/getDataByDomainLanguage",
    getInitialConf :"/auth/getInitialConf",
	getFiles:"/files/getfiles"+ (process.env.REACT_APP_PATH_IMAGES_MANAGER?process.env.REACT_APP_PATH_IMAGES_MANAGER:"S3"  ),
	createFileFolder:"/files/create-folder"+ (process.env.REACT_APP_PATH_IMAGES_MANAGER?process.env.REACT_APP_PATH_IMAGES_MANAGER:"S3"  ),
	uploadFile:"/files/upload"+ (process.env.REACT_APP_PATH_IMAGES_MANAGER?process.env.REACT_APP_PATH_IMAGES_MANAGER:"S3"  ),
	deleteFile:"/files/delete"+ (process.env.REACT_APP_PATH_IMAGES_MANAGER?process.env.REACT_APP_PATH_IMAGES_MANAGER:"S3"  ),
	CreateUser:"/public/users/register",
	RecoreyPass: '/public/users/recover-password',
	bulkData :	'/files/bulk-data'	,
	getBulkData: '/files/get-bulk-data'	,
	updatePassword: '/users/update-password'
}