import React, { useState, useRef, useCallback } from "react";
 
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Dialog, Button, Card, OutlinedInput, InputAdornment, Typography, ImageList ,TextareaAutosize , ImageListItem, FormLabel,RadioGroup, Radio, FormControlLabel, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";
import Loading from "../../components/Loading";
import FileManagerDialog from "./FileManagerDialog";
import { find,  parseDataInfo, preloadForm, completeForm,getImagePath, simplePostData, simpleGetData } from '../../utils/Functions';
import { useSelector } from "react-redux";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add'; 
import RemoveIcon from '@mui/icons-material/Remove';

import Geocode from "react-geocode";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GoogleMapsAPI = process.env.REACT_APP_GOOGLEMAP_KEY

Geocode.setApiKey( GoogleMapsAPI );
Geocode.enableDebug();
Geocode.setRegion("es");
Geocode.setLanguage("es");

var locationInfo = { lat:-17.8145819 , lng: -63.1560853}
const ComponentPolygonDialog = (props) =>  {
	
	const {defLang, defConfig} = useSelector((state) => state.Login);	
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [isFetching,setIsFetching]    = useState(false)
	const [values,setValues]    = useState([])
	
	const [city,setCity]    = useState("")
	const [country,setCountry]    = useState("")
	const [preloadpolygon,setPreloadpolygon]    = useState("")
	
	
//	const [locationInfo, setLocationInfo] = useState({ lat:-16.489689 , lng: -68.1192935})
	 
	const [path, setPath] = useState([ ]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
   
	const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      
      setPath(nextPath);
      //locationInfo =  nextPath[0]
    }
  }, [setPath]);
  
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );
	
	const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);
  
  const handleChange = (event) => {
		
		if (event.target.name =="city" ){
			setCity(event.target.value)
		}else if (event.target.name =="country" ){
			setCountry(event.target.value)
		}else if (event.target.name =="preloadpolygon" ){
			setPreloadpolygon(event.target.value)
		}

	}
	
	const preloadCoords =async (event) => {
		let _preloadpolygon = preloadpolygon
		let coords=[]
		if (preloadpolygon.length>0){
			_preloadpolygon.replaceAll("[","")
			_preloadpolygon.replaceAll("]","")
			_preloadpolygon.replaceAll(" ","")
			
			_preloadpolygon.replaceAll(/(\r\n|\n|\r)/gm, "")
			
			_preloadpolygon.replaceAll(/\t/g, "");
			
			let _precoords = _preloadpolygon.split(";")
			if (_precoords.length>0){
				
				_precoords.map(function (key) {
  				let _coord = key.split(",")		
  				let coord
  				if (_coord.length==2){
  					let lat = parseFloat(_coord[0])
  					let lng = parseFloat(_coord[1])
  					 
  					if ( !isNaN(lat) && !isNaN(lng) ){
  						coord= { lat: lat, lng: lng}
	  					coords.push (coord)	
  					}
  				}
  			}); 				
			}
		}
		 
		if (coords.length>0){
		//	console.log("coords",coords)
			setIsFetching(true);
			Geocode.fromLatLng( coords[0].lat , coords[0].lng ).then(
				response => {
					if (response.results.length>0){
						
						setPath(coords)
						 
					  locationInfo = { lat: coords[0].lat,lng: coords[0].lng }
		
					} 
					setIsFetching(false);
					toast.success(defConfig[defLang].loadsuccessfulmessage);	
				},
				error => { 
				//	console.log("err")
					setIsFetching(false); 
				}
			);
		}else{
			toast.error(defConfig[defLang].invalidformatmessage);	
		}
	};
  
  const searchCity =async (event) => {
		setIsFetching(true);
		Geocode.fromAddress( city +", "+country ).then(
			response => {
				if (response.results.length>0){
					setPath(
					[
				    { lat: response.results[0].geometry.location.lat     ,lng: response.results[0].geometry.location.lng },
				    { lat: response.results[0].geometry.location.lat-0.0130 ,lng: response.results[0].geometry.location.lng-0.0010 },
				    { lat: response.results[0].geometry.location.lat-0.0141 ,lng: response.results[0].geometry.location.lng-0.0123 },
				    { lat: response.results[0].geometry.location.lat-0.0016 , lng: response.results[0].geometry.location.lng-0.0125 }
				  ])
				  locationInfo = { lat: response.results[0].geometry.location.lat -0.0116    ,lng: response.results[0].geometry.location.lng }
				} 
				setIsFetching(false);
			},
			error => { 
				//console.log("err")
				setIsFetching(false); 
			}
		);
		
	};
	
	const getCities = async(event) => {
		var resp= await simplePostData( "/cities/get-all", {} ) 
  	var cities= []
  	resp.result.map(function (key, item) {
			 cities.push({ value: key.ORGANIZATIONID , text: key.NAME })
		}); 
		setCountry("BOLIVIA")
	//	setStoreids(storeids)
		//setCity(cities[0].value)
	}
	
  React.useEffect(()=>{
  	
  	
	 	if (props.value){
	 		if (props.value.length>0){
	 			getCities()
	 			setPath(props.value)
	 			locationInfo = {lat: props.value[0].lat , lng: props.value[0].lng}		
	 		}
	 	}else{
	 		setPath([
				    { lat: locationInfo.lat        ,lng: locationInfo.lng },
				    { lat: locationInfo.lat-0.0130 ,lng: locationInfo.lng-0.0010 },
				    { lat: locationInfo.lat-0.0141 ,lng: locationInfo.lng-0.0123 },
				    { lat: locationInfo.lat-0.0016 ,lng: locationInfo.lng-0.0125 }
				  ])
	 	}
	 	
  },[props]) 
  
		 
  return (
    <Dialog fullWidth={true}  maxWidth="lg" scroll="paper" TransitionComponent={Transition} open={props.open} onClose={props.onClose} maxWidth="lg"  fullScreen={fullScreen} >
     	{isFetching ? <Loading />  : ""}
     	
     	<Card sx={{ p:2, mb:1}}>
			
				<Grid item xs={12} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
					  <CloseIcon />
					</IconButton>
      	</Grid>
      	
				<Grid container px={1 }>
					<Grid item xs={12} mb={1} >
						Busque un producto para agregarlo
					</Grid>
  			 	<Grid item xs={3} mb={1} >
  			 		<FormControl fullWidth>
							<InputLabel>{defConfig[defLang].countrylabel}</InputLabel>
        			<Select
                name={"country"}
						    value={country}
						    size="small"
						    label={defConfig[defLang].countrylabel}
						    onChange={handleChange}>
						   			<MenuItem value="BOLIVIA">BOLIVIA</MenuItem>
						  </Select>
					  </FormControl>
          </Grid>
          <Grid item xs={6} lg={4} pl={1} >

		  		<TextField
				                     
				                      fullWidth
									  size="small"
				                      label={defConfig[defLang].citylabel}
				                      name={"city"} 
				                      value={city}
									  onChange={handleChange}
									  />

	      		 
        	</Grid>	
					<Grid item xs={2} pl={1} >
	      		<Button
              type="button"
              variant="contained"
              color="success"
              onClick={searchCity}
              sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
          	>{defConfig[defLang].searchlabel}</Button>
        	</Grid>	 
          
          <Grid item xs={12} mb={1} >
						 <TextField               
                fullWidth
                multiline                 
                rows={2}
                label={defConfig[defLang].preloadpolygonlabel}
                name="preloadpolygon"
                value={preloadpolygon} 
                placeHolder="asi ru"
                onChange={handleChange}
              />
					</Grid>
					<Grid item xs={12} pl={1} sx={{textAlign:"right"}} >
						<span style={{fontSize:10}}>
							{defConfig[defLang].formatpolygonmessage} 
						</span>  		
					 
	      		<Button
              type="button"
              variant="contained"
              color="info"
              onClick={preloadCoords}
              sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
          	>{defConfig[defLang].loadlabel}</Button>
        	</Grid>	 
		      	
        </Grid> 
     	</Card>
     	
			<Card sx={{ p:2, mt:2, mb:2}}>
			
  			<Grid mb={1} spacing={2}  container sx={{fontSize:12, borderBottom: "1px solid", borderColor:"success.main",    pb:1 }} >
  			 	<Grid item xs={12}  px={1}>
						 
						 <div className="App-map-container">
						  
					        
					        <GoogleMap
					          mapContainerClassName="App-map"
					          defaultCenter={locationInfo}
					          center={locationInfo}
					          zoom={13}
					          version="weekly"
					          on
					        >
					          <Polygon
					            // Make the Polygon editable / draggable
					            editable
					            draggable
					            path={path}
					            // Event used when manipulating and adding points
					            onMouseUp={onEdit}
					            // Event used when dragging the whole Polygon
					            onDragEnd={onEdit}
					            onLoad={onLoad}
					            onUnmount={onUnmount}
					          />
					        </GoogleMap>
					        <div className="App-coords">
					        	<Grid container  spacing={0} >
						        	{path.length>0 && 
						        		<>
							        		<Grid item xs={6}>
								  					<Typography variant="body2">
															{defConfig[defLang].latitudelabel}
														</Typography>			      	
													</Grid>
													<Grid item xs={6} >
														<Typography variant="body2">
															{defConfig[defLang].longitudelabel} 
														</Typography>  						
							            </Grid>
						            </>
						        	}
						        	{path.map((coord, index) => {
										    return(
											    <>
								        		<Grid key={"lat"+index} item xs={6}>
									  					<Typography key={"tlat"+index} variant="body2">
																{coord.lat + "," }
															</Typography>			      	
														</Grid>
														<Grid key={"lng"+index} item xs={6} >
															<Typography key={"tlng"+index} variant="body2">
																{coord.lng+";"}
															</Typography>  						
								            </Grid>
							            </>
										    	 
										    )
		         					})}
					        	</Grid>
					        </div>
					      
				    </div>    
          </Grid>		              
					<Grid item xs={12} sx={{textAlign: "right"}} pr={2} pt={2}  >
	      		<Button
	            type="button"
	            variant="contained"
	            color="success"
	            	onClick={ ()=> { 
	             		let finalevent = { target: {name: props.fieldName, value:path, type:"componentpolygon" }}
  								props.handleChange(finalevent)
	             	
	             		props.onClose() }}
	            sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
	        	> {defConfig[defLang].savelabel}
	        	</Button>
	      	</Grid>
	      </Grid>
   		</Card> 
       
  </Dialog>
  );
};

ComponentPolygonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ComponentPolygonDialog;
