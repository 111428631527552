import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loading from "./components/Loading";
 
import { getInitialConf} from './utils/Functions';

const Route = (props) => {
  const { user, showDashboard, defLang, requestingLogin } = useSelector((state) => state.Login);
  const {  requesting, processFailed, processMessage, processError,reloadPage } = useSelector((state) => state.Process);
  const [openPanel, setOpenPanel] = useState(false);
  
  const [isFatching, setIsFatching] = useState(true);
  
  const [theme, setTheme] = useState();
  
   React.useEffect(()=>{
   	
   		const fetchFn = async ()=>{
			setIsFatching(true) 			      
      const formStructure=[]
      var response = await getInitialConf(); 
     	const _theme = createTheme({
										  palette: response.palette,
										  typography: {
										    // In Chinese and Japanese the characters are usually larger,
										    // so a smaller fontsize may be appropriate.
										    fontSize: 11,
										    fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
										  /*  button: {
										      textTransform: "none"
										    }*/
										  },
										  overrides: {
										    MuiListItem: {
										      root: {
										        "&$selected": {
										          color: "#FFF",
										          backgroundColor:"red"
										        }
										      }
										    }
										  }, 
										});
   		setTheme(_theme)
    	setIsFatching(false)
    }
 		fetchFn(); 
  	if (reloadPage){
  		window.location.reload();
  	}
  },[reloadPage])
  
  
  return (isFatching?"":
		<ThemeProvider theme={theme}>
			
			{requestingLogin && <Loading/> }
			{requesting && <Loading/> }
			<ToastContainer
			 			closeButton={true}
	          autoClose={2500}
	          hideProgressBar={true}
	          position={"bottom-right"}
	           />
      <BrowserRouter>
        <Routes
          defLang={defLang}
          reloadPage={reloadPage}
        />
      </BrowserRouter> 
    </ThemeProvider>
  );
};

export default Route;
