import * as React from 'react';
import PropTypes from 'prop-types';
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { Dialog,  Card,  InputAdornment, Typography, ImageList ,TextareaAutosize , ImageListItem, FormLabel,RadioGroup, Radio, FormControlLabel, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {  getImagePath } from '../../utils/Functions';
const GoogleMapsAPI = process.env.REACT_APP_GOOGLEMAP_KEY
const CompCardContainer = (props) => {
  const {defLang, defConfig} = useSelector((state) => state.Login);
  
  return (!props.field.value?"" :  props.field.value.length>0 &&
			<Grid item xs={12} >
				<Card>
					<div className="App-map-container-mini">
						
			         <GoogleMap
			          mapContainerClassName="App-map-mini"
			          defaultCenter={{lat: props.field.value[0].lat, lng : props.field.value[0].lng}}
			          center={{lat: props.field.value[0].lat, lng : props.field.value[0].lng}}
			          zoom={12}
			          version="weekly"
			          on
			        >
			          <Polygon 
			            path={props.field.value} 
			          />
			        </GoogleMap> 
			       
					</div>
	  			<Grid container p={2} spacing={1} sx={{borderBottom: "1px solid"}}>
	  				<Grid item xs={6}>
	  					<Typography variant="h6">
								{defConfig[defLang].latitudelabel}
							</Typography>			      	
						</Grid>
						<Grid item xs={6} >
							<Typography variant="h6">
								{defConfig[defLang].longitudelabel} 
							</Typography>  						
            </Grid>
	  				{props.field.value.map((value, index) => {
	  					return(
	        			<>
			            	<Grid key={"col1"+index} item xs={6}>
		            			<TextField
					              size="small"
					              fullWidth 
					              disabled
					              key={"lat"+index}
					              name="product"
					              value={value.lat}
					              
					            />
										</Grid>
										<Grid key={"col2"+index} item xs={6} >
          						 <TextField
						              size="small"
						              fullWidth 
						              disabled
						              key={"lng"+index}
						              name="product"
						              value={value.lng}
            						/>
			              </Grid>
								</>
	          	)
						})}  
	          
	        </Grid>  
	      </Card> 
	  	</Grid>
												      	 
  );
};

CompCardContainer.propTypes = {};

export default CompCardContainer;

