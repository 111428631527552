import React, { useState, useCallback, useEffect } from 'react';
import { AddressAutofill, AddressMinimap, useConfirmAddress, config } from '@mapbox/search-js-react';
import { Paper, InputBase,IconButton  } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
const MapBox = (props) => {	
	const navigate = useNavigate();
	const { defConfig, params, defLang } = useSelector((state) => state.Login);
	 
 
	const [feature, setFeature] = useState();
	
	const [token, setToken] = useState('');
	const [value, setValue] = React.useState([]);
	
	useEffect(() => {
		const accessToken = process.env.REACT_APP_MAPBOX_KEY
		setToken(accessToken)
		config.accessToken = accessToken;
	}, [])

	const handleChange = (event) => {
    setFeature(event.target.value);
    setValue([])
  };
  
	const { formRef, showConfirm } = useConfirmAddress({
			
	minimap: true,
	skipConfirmModal: (feature) => {
	['exact', 'high'].includes(feature.properties.match_code.confidence)
	}
	});
 
	 
	const handleRetrieve = useCallback(
		(res) => {
			console.log(res.features[0])
		const feature = res.features[0].properties.place_name;
		const coordinates = res.features[0].geometry.coordinates;
		setValue(coordinates)
		setFeature(feature);
		},
		[setFeature,value]
	);
   
  
	return (
		<>
			<form ref={formRef} >
				<div className="grid grid--gut24 mb60">
					<div className="col col--auto-mm w-full">
				 
						<AddressAutofill accessToken={token} onRetrieve={handleRetrieve}>
							<Paper component="form" sx={{width: "100%"}} >
								<InputBase
									id="mapbox-autofill"
									value={feature}
									onChange={(event) => handleChange(event)}
								  sx={{ ml:2, width: {  xs: "75%", md: "80%" } }}
								  placeholder={defConfig[defLang].enteraddress}
								  inputProps={{ 'aria-label': 'search google maps' }}
								/>
								<IconButton onClick={()=>{ navigate("/dashboard"); }}  disabled={value.length==0?true:false  } type="button" sx={{ p: '10px' }} aria-label="search">
					        <SearchIcon />
					    	</IconButton>
					    </Paper>
						</AddressAutofill>
					</div>
				</div>
			</form>  
		</>
	);
};


MapBox.propTypes = {};

export default MapBox;
