import React               from "react";
import { Button, Card, OutlinedInput, InputAdornment, Typography, ImageList ,TextareaAutosize , ImageListItem, FormLabel,RadioGroup, Radio, FormControlLabel, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";
import { Box }             from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions/process.actions";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { toast } from "react-toastify";
/**********************START ADMIN COMPONENTS***********************/
/*FORM COMPONENTS*/
import CompMultiAutocomplete from "./CompMultiAutocomplete"; 
import CompAutocomplete from "./CompAutocomplete"; 
/*DIALOG COMPONENTS*/
import FileManagerDialog from "../dialog/FileManagerDialog";
import ComponentSliderBannerDialog from "../dialog/ComponentSliderBannerDialog";
import ComponentCardContainerDialog from "../dialog/ComponentCardContainerDialog";
import ComponentFormContainerDialog from "../dialog/ComponentFormContainerDialog";

import ComponentListDialog from "../dialog/ComponentListDialog";

import CompSliderBannerForm from "./CompSliderBannerForm";
import CompCardContainerForm from "./CompCardContainerForm";
import CompFormContainerForm from "./CompFormContainerForm";
import CompPolygonForm from "./CompPolygonForm";
import CompListForm from "./CompListForm";

import ComponentPolygonDialog from "../dialog/ComponentPolygonDialog";


/**********************END ADMIN COMPONENTS***********************/



/*********************EXTRA COMPONENTS*******************************/

/*DIALOG COMPONENTS*/
import CompOrderItemsContainerForm from "./CompOrderItemsContainerForm"; 
import ComponentOrderItemsDialog from "../dialog/ComponentOrderItemsDialog";



/*********************END EXTRA COMPONENTS*******************************/

import defImage from "../../assets/images/defImage.jpg";
/*CUSTOM FUNCTIONS*/
import { find,  parseDataInfo, preloadForm, completeForm,getImagePath, simplePostData, simpleGetData } from '../../utils/Functions';
import es from "date-fns/locale/es";
registerLocale("es", es); 

const GoogleMapsAPI = process.env.REACT_APP_GOOGLEMAP_KEY

const GenericForm = (props) => {
	
	const dispatch = useDispatch();
	const { processSuccess,  processError, requesting, processMessage, formFields, initialValues, validationSchema} = useSelector(
    (state) => state.Process
  );
  
  const {defLang, defConfig} = useSelector((state) => state.Login);
  const [openFileManagerDialog, setOpenFileManagerDialog] = React.useState(false);
	const [openComponentSliderBannerDialog, setOpenComponentSliderBannerDialog] = React.useState(false);
	const [openComponentCardContainerDialog, setOpenComponentCardContainerDialog] = React.useState(false);
	const [openComponentFormContainerDialog, setOpenComponentFormContainerDialog] = React.useState(false);
	
	const [openComponentOrderItemsDialog, setOpenComponentOrderItemsDialog] = React.useState(false);
	const [openComponentPolygonDialog, setOpenComponentPolygonDialog] = React.useState(false);

	const [openComponentListDialog, setOpenComponentListDialog] = React.useState(false);
	
	
	const [fieldName, setFieldName] = React.useState("");
	const [fieldValue, setFieldValue] = React.useState(null);
	const [fieldValueOI, setFieldValueOI] = React.useState(null);
	const [fieldValueCoord, setFieldValueCoord] = React.useState([]);

	const [fieldValueL, setFieldValueL] = React.useState([]);
	
	const [fieldValueF, setFieldValueF] = React.useState(null);
	const [multipleImages, setMultipleImages] = React.useState(false);
	const [refreshingData, setRefreshingData] = React.useState(false);
	const [refreshingImage, setRefreshingImage] = React.useState(false);
	
	const [showPasswords, setShowPasswords] = React.useState({})
	 
	const [finalFormFields,  setFinalFormFields] = React.useState([])	 
	  const formik = useFormik({
    initialValues: props.formData?.formValues?props.formData.formValues: initialValues,
    enableReinitialize:true,
    validateOnBlur: true,
    validationSchema: props.formData?.formValidation?props.formData.formValidation: validationSchema,
    onSubmit: (values, {setSubmitting, setErrors, setStatus, resetForm} ) => {
    	
		values =  completeForm (values, finalFormFields)
		var isValid = true
		
		console.log(finalFormFields)
		//extra validations
		for (let i in finalFormFields){
			let field = finalFormFields[i]

			if(field.customvalidations?.indexOf("required")>=0 ){
				
				if (field.type==="multipleimages" ){
					//console.log("MIR", field )
					if ( field.value.length===0){
						isValid = false
						toast.warning( "El campo "+field.label+ " no puede estar vacio");	
					}
				}
			}
		}
    
		

		if (isValid){
			values["id"] = props.id
			console.log( finalFormFields, values)
			dispatch(Actions.processData( props.componentName, props.action,values  ))
		}

		
     
    },
  });
	const setFormDate =(field,date ) => {
		let fields = finalFormFields; 
		
		setRefreshingData(true)
		let pos = find(finalFormFields,field, "name"  )  	
	 	fields[pos].value = (date)
	 	
	 	setTimeout(() => {
    	setFinalFormFields(fields) 
			setRefreshingData(false)
    }, 1);
	}
	
	const loadPreviewform = async(event) => {
		
		let fields = finalFormFields; 
		setRefreshingData(true);
		let postloadscript = ""
		try{
			postloadscript= formik.values[event.target.name]	
		}catch(Exc){
			//console.log("no script")
		}
		
		for (let f  in fields){
			let field = fields[f]
			if (field.type=='componentformcontainer'){
				let data={}								
				if (fields[f]["loaded"]){
					/*
					data = field["formattedvalue"]
					data =await preloadForm(data, postloadscript)	
					*/
					data = field["value"]
					data =await parseDataInfo(data, postloadscript)		
				}else{
					data = field["value"]
					data =await parseDataInfo(data, postloadscript)		
				}
				fields[f]["formattedvalue"] = data
				fields[f]["loaded"]= true
			}
		}
		setTimeout(() => {
      setFinalFormFields(fields) 
			setRefreshingData(false)
    }, 1);
	}

	const handleChange = (event) => {

  	let pos = find(finalFormFields,event.target.name, "name"  )  	
 
  	let fields = finalFormFields; 
  	if (Array.isArray(event.target.value)){
  		let newValue=[]  		
  		event.target.value.map(function (key) {
  			if (event.target.type=="component" || event.target.type=="form" || event.target.type=="orderitems" || event.target.type=="componentpolygon" ){
					newValue.push( key)  				
	  		}else{
	  			newValue.push( key.value.toString() )
	  		}
  		}); 
  		if (event.target.type=="component"){
  			fields[pos].value = newValue
  			setFieldValue(newValue)	
  		}else if (event.target.type=="form"){
  			fields[pos].value = newValue
  			setFieldValueF(newValue)	
  		}else if (event.target.type=="orderitems"){
  			fields[pos].value = newValue
  			setFieldValueF(newValue)	
  		}else if (event.target.type=="componentpolygon"){
  			fields[pos].value = newValue
  			setFieldValueCoord(newValue)	
  		}else{
  			fields[pos].value = newValue
  		}
  	}else{	
  		fields[pos].value = event.target.value		
  	} 
    
	if (event.target?.type ==="file"){
		setRefreshingImage(true)
		setTimeout(async() => {
			setRefreshingImage(false)
			setFinalFormFields(fields) 
		}, 1);    	
	}else{
		setFinalFormFields(fields) 
	}
    
    if (event.target.type=="form"){
		setTimeout(async() => {
	   		await loadPreviewform()	
    	}, 1);    	
    }
      
  };
  
  
  const handleClickShowPassword = (field) => {
  	
  	setRefreshingData(true)

  	setTimeout(() => {
    	let _showPasswords = showPasswords
	  	if (_showPasswords[field]){
	  		_showPasswords[field]=false
	  	}else{
	  		_showPasswords[field]=true
	  	}
	    setShowPasswords(_showPasswords);
			setRefreshingData(false)
    }, 1);
    
  	
  };
  
  const handleMouseDownPassword = (event) => {

    //event.preventDefault();
  };
  
  React.useEffect(() => {
  	
  	if (props.formData){
  		setFinalFormFields(props.formData.data) 
  	}else{
		 	if (formFields.length>0){
	   		setFinalFormFields(formFields) 
	  	}
	  }  	
  	if (processSuccess){
  		dispatch(Actions.getComponentConfig(props.componentName))	
  	}
  }, [formFields, processSuccess ]);
	
	const minHeight = window.innerHeight-120
	
	const handleOnChange =async (event) => {
		var action=""
		var formData = finalFormFields
		var field ={}
		try{
			const index = finalFormFields.findIndex((item) => item.name === event.target.name);
		  
		  if ( formData[index].action ){
		  	if ( formData[index].action!="" ){
		  		field = formData[index]
		  		field["value"] = event.target.value
		  	
		  		action = formData[index].action
		  		const {simpleGetData} = require("../../utils/Functions");
	    		const {simplePostData} = require("../../utils/Functions");
	    		
	    		if (action.indexOf ("async")>=0){	    			
	    			setRefreshingData(true)
	    			await eval(action)	    			
	    			setRefreshingData(false)
	    			
	    		}else{	    			
	    			eval(action)
	    		}
	    		
		  		setFinalFormFields(formData)
			  }
		  }	
		}catch(Exc){
			//console.log(">>>>Err", Exc)
			if ( action!="" ){
				//console.log("action", action)
			}
		}
		
	};

  return (
    <Container>
    	<FileManagerDialog multipleImages={multipleImages} onSetFile={handleChange} id={props.id} fieldName={fieldName} open={openFileManagerDialog}  onClose={()=>{ setOpenFileManagerDialog(false) } } />
    	<ComponentSliderBannerDialog value={fieldValue} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentSliderBannerDialog}  onClose={()=>{ setOpenComponentSliderBannerDialog(false) } } />
    	<ComponentCardContainerDialog value={fieldValue} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentCardContainerDialog}  onClose={()=>{ setOpenComponentCardContainerDialog(false) } } />
    	<ComponentOrderItemsDialog value={fieldValueOI} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentOrderItemsDialog}  onClose={()=>{ setOpenComponentOrderItemsDialog(false) } } />
    	
    	<ComponentPolygonDialog value={fieldValueCoord} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentPolygonDialog}  onClose={()=>{ setOpenComponentPolygonDialog(false) } } />

		<ComponentListDialog value={fieldValueL} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentListDialog}  onClose={()=>{ setOpenComponentListDialog(false) } } />
    	
    	
    	{fieldValueF!=null &&
    		<ComponentFormContainerDialog value={fieldValueF} handleChange={handleChange} id={props.id} fieldName={fieldName} open={openComponentFormContainerDialog}  onClose={()=>{ setFieldValueF(null); setOpenComponentFormContainerDialog(false) } } />
    	}
	 		<form  onSubmit={formik.handleSubmit} onChange={handleOnChange}	>
	    	<Grid container   >
	      	<Grid item lg={12} xs={12}   >
	       {/*   <Card sx={{minHeight: minHeight }}> */}
	            <Container style={{ marginTop: 10 }}>
	              <Grid container spacing={1}>
		              {finalFormFields.map((field,index) => {
										return( field.type === "hidden"?
			                     <input
			                     	type="hidden"
			                     	key={"field"+index}
			                      name={field.name}
			                      value={formik.values[field.name]}
			                    />
			             		:
											<>
											{!field.hide &&
			              		<Grid key={"grid"+index} item sx={{mt:1, textAlign:"left"}} xs={field.size.xs} lg={field.size.lg} >
			              	 		
			              	 		{field.type === "multipleimages" && 
			              	 			<> 
										 	<span style={{color:"#5e5e5e", fontSize: 12 , marginLeft:15}}>	{field.label} </span>
			              	 				{field.value.length>0 ?
			              	 					
			              	 					
			              	 						<ImageList variant="quilted" sx={{ /*width: 500,*/ maxHeight: 450 }} cols={3} rowHeight={field.rowheight?field.rowheight:164}>
															    	{field.value.map((image,index) => {
															    		return(
																        <ImageListItem key={"listimage"+field.name+image}>
																          <img
																            src={`${getImagePath(image)}?w=164&h=164&fit=crop&auto=format`}
																            srcSet={`${getImagePath(image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
																            alt={image}
																            loading="lazy"
																          />
																        </ImageListItem>
															        )
															      })}
															    </ImageList>
			             							
															:
																 <TextField
						                     	key={"field"+index}
						                      fullWidth 
						                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
								                	helperText={formik.touched[field.name] && formik.errors[field.name]}
						                      name={field.name}
						                      value={formik.values[field.name]}
						                      disabled
						                    />
															}
															<Button
								                type="button"
								                variant="contained"
								                color="info"
								                disabled={field.disabled || props.allDisabled}
								                onClick={()=>{ setMultipleImages(true); setOpenFileManagerDialog(true) ; setFieldName(field.name) }}
								                sx={{ mt: field.value.length>0?"-103px":"-79px", ml:field.value.length>0?"7px":"11px", textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
								            	> {field.value!=""? defConfig[defLang].changeimagelabel: defConfig[defLang].chooseimagelabel}
								            	</Button>
							           		</>
			                 		}
			              	 		{!refreshingData && field.type == "staticimage" &&
										<div className="form-image">
								           	<img 
																	key={"img"+index} 
																	name={"selectItem_"+index}  
																	style={{maxHeight:"250px", maxWidth:"100%", cursor:"pointer" }} 
																	src={field.value!=""?getImagePath(field.value):  defImage}  
											/>
										</div>
						           	}
			              	 		{field.type === "image" && 
			              	 			<div className="image-container">
			              	 				{!refreshingImage && field.value!=="" ?
											<div className="form-image">
										           	<img 
																			key={"img"+index} 
																			name={"selectItem_"+index}  
																			style={{maxHeight:"250px", maxWidth:"100%",cursor:"pointer" }}
																			onClick={()=>{ setMultipleImages(false); setOpenFileManagerDialog(true) ; setFieldName(field.name) }}
																			src={getImagePath(field.value)} 
																			alt={field.name}
													/>
												</div>
															:
																 <TextField
						                     	key={"field"+index}
						                    	fullWidth 
						                      	error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
								                helperText={formik.touched[field.name] && formik.errors[field.name]}
						                      	name={field.name}
						                      	value={formik.values[field.name]}
						                      	disabled
						                    />
															}
												<Button
													type="button"
													variant="contained"
													color="info"
													disabled={field.disabled || props.allDisabled}
													onClick={()=>{ setMultipleImages(false); setOpenFileManagerDialog(true) ; setFieldName(field.name) }}
													sx={{ position: "relative",bottom: (field.value!=="" ? 46: 42) , float: "right",right: 10,textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
													> {field.value!=""? defConfig[defLang].changeimagelabel: defConfig[defLang].chooseimagelabel}
								            	</Button>

												{field.value!="" &&
													<IconButton
														sx={{ position: "relative",top: -270, float: "right",right: -128, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
														onClick={ ()=>{ handleChange( {target: {name : field.name,  value:"", type:"file" }  } )  }} color="error">
														<DeleteIcon fontSize="inherit" />
													</IconButton>
					  							}
							           		</div>
			                 		}
			                 		
			                 		{field.type === "componentpolygon" && 
			                 			<>
			                 			<LoadScript
											        id="script-loader"
											        googleMapsApiKey={GoogleMapsAPI}
											        language="en"
											        region="us">
				                 		 	<CompPolygonForm disabled={field.disabled || props.allDisabled} field={field} />
									            <Grid mt={1} sx={{textAlign:"right"}}>
							                  <Button
									                type="button"
									                variant="contained"
									                color="info"
									                onClick={()=>{  setFieldName(field.name); setFieldValueCoord(field.value); setOpenComponentPolygonDialog(true) ;  }}
									                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
									            	> {field.value.length>0?defConfig[defLang].editpolygonlabel: defConfig[defLang].createpolygonlabel}
									            	</Button>
									            </Grid>
									          </LoadScript>  
									            
								          	</>
						              }
			                 		
			                 		  {field.type === "componentsliderbanner" && 
			                 			<>
				                 			<CompSliderBannerForm field={field} disabled={field.disabled || props.allDisabled} />
				                 					
									            <Grid mt={1} sx={{textAlign:"right"}}>
							                  <Button
									                type="button"
									                variant="contained"
									                color="info"
									                onClick={()=>{  setFieldName(field.name); setFieldValue(field.value); setOpenComponentSliderBannerDialog(true) ;  }}
									                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
									            	> {field.value.length>0?defConfig[defLang].editlistlabel: defConfig[defLang].createlistlabel}
									            	</Button>
									            </Grid>
								          	</>
						              }

									  {field.type === "componentlist" && 
			                 			<>
				                 			<CompListForm field={field} disabled={field.disabled || props.allDisabled} />
				                 					
											<Grid mt={1} sx={{textAlign:"right"}}>
												<Button
													type="button"
													variant="contained"
													color="info"
													onClick={()=>{  setFieldName(field.name); setFieldValue(field.value); setOpenComponentListDialog(true) ;  }}
													sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}> 
													{field.value.length>0?defConfig[defLang].editlistlabel: defConfig[defLang].createlistlabel}
												</Button>
											</Grid>
								        </>
						              }

						              
						              {!refreshingData && field.type === "componentcardcontainer" && 
			                 			<>
				                 			<CompCardContainerForm disabled={field.disabled || props.allDisabled} field={field} />
				                 					
									            <Grid mt={1} sx={{textAlign:"right"}}>
							                  <Button
									                type="button"
									                variant="contained"
									                color="info"
									                onClick={()=>{  setFieldName(field.name); setFieldValue(field.value); setOpenComponentCardContainerDialog(true) ;  }}
									                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
									            	> {field.value.length>0?defConfig[defLang].edititemslabel: defConfig[defLang].createitemslabel}
									            	</Button>
									            </Grid>
								          	</>
						              }
						              
						              {field.type === "componentformcontainer" && 
			                 			<Card  sx={{ border: "1px solid", borderColor:"success.main", padding:1}}>
				                 			<CompFormContainerForm disabled={field.disabled || props.allDisabled} field={field} />
				                 					
									            <Grid mt={1} sx={{textAlign:"right"}}>
									            	{field.value.length>0 &&
										            	<Button
										                type="button"
										                variant="outlined"
										                color="info"
										                onClick={()=>{ loadPreviewform()  }}
										                sx={{ mr:1, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
										            	> {refreshingData? defConfig[defLang].refreshingformlabel: defConfig[defLang].loadformlabel}
										            	</Button>
									            	}
							                  <Button
									                type="button"
									                variant="contained"
									                color="info"
									                onClick={()=>{ setFieldName(field.name); setFieldValueF(field.value); setOpenComponentFormContainerDialog(true) ;  }}
									                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
									            	> {field.value.length>0? defConfig[defLang].editfieldslabel: defConfig[defLang].createfieldslabel }
									            	</Button>
									            </Grid>
								          	</Card>
								          	
						              }
						              
						              {field.type === "orderitems" && 
			                 			<Card  sx={{ border: "1px solid", borderColor:"success.main", padding:1}}>
				                 			<CompOrderItemsContainerForm disabled={field.disabled || props.allDisabled} field={field} />
				                 					
									            <Grid mt={1} sx={{textAlign:"right"}}>
									            	 
							                  <Button
									                type="button"
									                variant="contained"
									                color="info"
									                onClick={()=>{  setFieldName(field.name); setFieldValueOI(field.value); setOpenComponentOrderItemsDialog(true) ;  }}
									                
									                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
									            	> {field.value.length>0? defConfig[defLang].editfieldslabel: defConfig[defLang].createfieldslabel }
									            	</Button>
									            </Grid>
								          	</Card>
								          	
						              }
			              	 		
			              	 		{field.type === "title" && 
														<Typography variant={field.variant} color={field.color+".main"}>
							              	{field.label}
								           	</Typography>
						              }
						                
						              {field.type === "date" && 
						              	<>
						              		{refreshingData ?
								              	<TextField
						                     	key={"field"+index}
						                      fullWidth
						                      label={field.label}
						                    />
				                    	:<div className="custom-container-datepicker">
								              		<label style={{"max-width": "90%"}} className="custom-label">
								              		 	<span > {field.label} </span>
								              		 </label>
																	<DatePicker
										              	showYearDropdown
										              	className="custom-datepicker"
										              	locale="es"
															      selected={field.value}
															      onChange={(date) => { if(!field.disabled){ setFormDate( field.name, date)}  }}
															      dateFormat="dd/MM/yyyy"
															    />
																</div>
															}
												    </>
						              }
						              
						            	{field.type === "radiobuttonh" && 
						                 <FormControl key={"formcontrolrh"+index}>
												      <FormLabel key={"formcontrolrhl"+index} >{field.label}</FormLabel>
												      <RadioGroup
												     	  key={"field"+index}
														    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
							                	helperText={formik.touched[field.name] && formik.errors[field.name]}
					                      name={field.name}
														    value={formik.values[field.name]}
														    label={field.label}
														    disabled={field.disabled || props.allDisabled}
														    onChange={formik.handleChange}
														    row
												      >
												        {field.values && field.values.map((value, index2) => {
														    return(
														    	<FormControlLabel key={"formcontrolrh"+index+index2} value={value.value} control={<Radio />} label={value.text} />
														    )
						         						})}
												      </RadioGroup>
												    </FormControl>
						              }
						              
						              {field.type === "radiobuttonv" && 
						                 <FormControl key={"formcontrolrv"+index}>
												      <FormLabel key={"formcontrolrvl"+index} >{field.label}</FormLabel>
												      <RadioGroup
												     	  key={"field"+index}
														    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
							                	helperText={formik.touched[field.name] && formik.errors[field.name]}
					                      name={field.name}
														    value={formik.values[field.name]}
														    label={field.label}
														    disabled={field.disabled || props.allDisabled}
														    onChange={formik.handleChange}
												      >
												        {field.values && field.values.map((value, index2) => {
														    return(
														    	<FormControlLabel key={"formcontrolrv"+index+index2} value={value.value} control={<Radio />} label={value.text} />
														    )
						         						})}
												      </RadioGroup>
												    </FormControl>
						              }
						              
						              {field.type === "password" && 
						              	<>
						              	{refreshingData?
						              		<FormControl fullWidth variant="outlined" >
											          <InputLabel htmlFor="password">{field.label}</InputLabel>
											          <OutlinedInput
											          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
							                    
							                    key={"field"+index}
															    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
								                	helperText={formik.touched[field.name] && formik.errors[field.name]}
						                      name={field.name}
															    value={formik.values[field.name]}
															    label={field.label}														     
											            type='password'
											            endAdornment={
											              <InputAdornment position="end">
											                <IconButton
											                  aria-label="toggle password visibility"
											                  edge="end"
											                >
											                  <LockIcon />
											                </IconButton>
											              </InputAdornment>
											            } 
											           
											      		/> 
											        </FormControl>
						              	:
							                <FormControl fullWidth variant="outlined" >
											          <InputLabel htmlFor="password">{field.label}</InputLabel>
											          <OutlinedInput
											          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
							                    
							                    
							                    key={"field"+index}
															    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
								                	helperText={formik.touched[field.name] && formik.errors[field.name]}
						                      name={field.name}
															    value={formik.values[field.name]}
															    label={field.label}
															    disabled={field.disabled || props.allDisabled}
															    onChange={formik.handleChange}
							                     
											            type={showPasswords[field.name] ? 'text' : 'password'}
											            endAdornment={
											              <InputAdornment position="end">
											                <IconButton
											                  aria-label="toggle password visibility"
											                  onClick={() =>{ handleClickShowPassword(field.name) }}
											                	
											                  edge="end"
											                >
											                  {showPasswords[field.name] ? <LockOpenIcon/> : <LockIcon />}
											                </IconButton>
											              </InputAdornment>
											            } 
											           
											      		/> 
											        </FormControl>
											      }
										        </>
						              }
			              	 		
				                	{field.type === "text" && 
				                     <TextField
				                     	key={"field"+index}
				                      fullWidth
				                      disabled={field.disabled || props.allDisabled}
				                      label={field.label}
				                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
						                	helperText={formik.touched[field.name] && formik.errors[field.name]}
				                      name={field.name}
				                      value={formik.values[field.name]}
				                      id={field.name}
				                      onChange={formik.handleChange}
				                    />
				                  }
				                  
				                  {field.type === "textarea" && 
				                     <TextField
				                     	key={"field"+index}
				                      fullWidth
				                      multiline
				                      disabled={field.disabled || props.allDisabled}
				                      rows={2}
				                      label={field.label}
				                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
						                	helperText={formik.touched[field.name] && formik.errors[field.name]}
				                      name={field.name}
				                      value={formik.values[field.name]}
				                      id={field.name}
				                      onChange={formik.handleChange}
				                    />
				                  }
				                  {field.type === "scriptarea" && 
				                  	<Card  sx={{ border: "1px solid", borderColor:"warning.main", padding:1}}>
					                  	<Grid container>
					                  		<Grid item xs={12}>
						                     <TextareaAutosize 
					                     		maxRows={20}
					                     		minRows={3}
					                     		style={{ width: "99%" }}
						                     	key={"field"+index}
						                      fullWidth
						                      multiline
						                      disabled={field.disabled || props.allDisabled} 
						                      label={field.label}
						                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
								                	helperText={formik.touched[field.name] && formik.errors[field.name]}
						                      name={field.name}
						                      value={formik.values[field.name]}
						                      id={field.name}
						                      onChange={formik.handleChange}/>
					                     	</Grid> 
									            	<Grid item  xs={12} mt={1} sx={{textAlign:"right"}}>
										            	<Button
										                type="button"
										                onClick={loadPreviewform}
										                name={field.name}
										                variant="contained"
										                disabled={field.disabled || props.allDisabled}
										                color="warning"
										                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
										            	>
										            		{defConfig[defLang].testscriptlabel}</Button>
									            	</Grid>
					                  	</Grid>
					                  </Card> 
				                  }
				                  {field.type === "autocomplete" && 
				                  	<>
				                  	{field.multiple?
				                    	<CompMultiAutocomplete disabled={field.disabled || props.allDisabled3} key={"field"+index} field={field} handleChange={handleChange} />
				                     		:
				                    	<CompAutocomplete disabled={field.disabled || props.allDisabled} key={"field"+index} field={field} handleChange={handleChange} />
				                    }
				                    </>
				                  }
				                  {field.type === "multipleautocomplete" && 
				                  	<>
				                    	<CompMultiAutocomplete disabled={field.disabled || props.allDisabled} key={"field"+index} field={field} handleChange={handleChange} />
				                    </>
				                  }
				                  
				                  
				                  {field.type === "select" && 
				                     <FormControl fullWidth>
														  <InputLabel>{field.label}</InputLabel>
														  <Select
														    key={"field"+index}
														    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
							                	helperText={formik.touched[field.name] && formik.errors[field.name]}
					                      name={field.name}
														    value={formik.values[field.name]}
														    label={field.label}
														    disabled={field.disabled || props.allDisabled}
														    onChange={formik.handleChange}
														  >
														  	{field.values && field.values.map((value) => {
														    return(
														    	<MenuItem value={value.value}>{value.text}</MenuItem>
														    )
				             						})}
														  </Select>
														</FormControl>
				                  }
				                  
												</Grid>
											}
											</>
		                )
		             	})}
		             </Grid>
								<Grid sx={{ mt: 2, mb: 2}} container justifyContent="flex-end"  >
		             	{props.buttonLabel==="NOBUTTON"?
		             	 	"":
								<>
									{props.backButton &&									
										<Button
											type="button"
											variant="outlined"
											color="primary"
											onClick={props.backButton}
											sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"} , mr:1 }}
											>Cancelar
										</Button>									
									}
									<Button
										type="submit"
										variant="contained"
										color="success"
										sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
										>{props.buttonLabel?props.buttonLabel:defConfig[defLang].savedatalabel}
									</Button>
								</>

				           }
								</Grid>
							</Container>
	       	{/*	</Card>*/}
					</Grid>
				</Grid>
		  </form>
	  </Container>
  ); 
	 
};

GenericForm.propTypes = {};

export default GenericForm;
