import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { 
	Container,
	Card, 
	Grid, 
	CardActions, 
	Link ,
	Divider, 
	InputLabel, 
	OutlinedInput, 
	Checkbox, 
	FormControlLabel, 
	FormControl, 
	CssBaseline, 
	Avatar, 
	CardContent , 	
	Button , 
	Box , 
	Typography, 
	Input, 
	InputAdornment,
	Paper,
	InputBase,
	IconButton,
	
	  } from "@mui/material";
 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import { styled } from '@mui/material/styles';

import * as UserActions from "../../redux/actions/user.actions";


const FBButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor:  theme.palette.googleFacebookColor,
  '&:hover': {
    backgroundColor: theme.palette.googleFacebookColor,
  }, 
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const FixedButton = styled(Button)(({ theme }) => ({   
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const JoinNewsLetterForm = props => { 
	const dispatch = useDispatch();
	const { defConfig, params, defLang, activeForm } = useSelector((state) => state.Login);
	const [showPassword, setShowPassword] = React.useState(false)
	
	const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
	const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    /*console.log({
      email: data.get('email'),
      password: data.get('password'),
    });*/
  };
   
  return (
    <Grid spacing={3} container component="form" noValidate onSubmit={handleSubmit}>
    	<Grid item xs={12} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
    		<Typography  color="primary" variant="h4" textAlign="left" fontWeight="bolder" gutterBottom>
	    		{defConfig[defLang].joinwaitlist2}
	    	</Typography>
      	<Typography  color="primary" variant="h6" textAlign="left"   gutterBottom>
	    		{defConfig[defLang].othersareusingtheir}
	    	</Typography> 
	    </Grid>	
    	<Grid item xs={12} md={6} lg={6}  >
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="fullname">{defConfig[defLang].formfullname}</InputLabel>
          <OutlinedInput
          
            id="fullname"
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton 
                  edge="end"
                >
                	<DnsRoundedIcon />
                </IconButton>
              </InputAdornment>
            } 
            label={defConfig[defLang].formfullname}
          />
        </FormControl> 
			</Grid>
			<Grid item xs={12} md={6} lg={6}  >
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="fullname">{defConfig[defLang].formemail}</InputLabel>
          <OutlinedInput
          
            id="password"
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                <MailOutlineIcon/>
                </IconButton>
              </InputAdornment>
            } 
            label={defConfig[defLang].formemail}
      	/>
        </FormControl>
      </Grid>  
      <Grid item xs={12} sx={{textAlign:"right"}}>
      	<FixedButton
          type="submit" 
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 2, px:6,fontWeight: "bold"  }}
      	>
      		<Typography variant="body1"  fontWeight="bolder">
		    		{defConfig[defLang].joinwaitlist}
		    	</Typography>
       	</FixedButton>
      </Grid> 
    </Grid>
  )
};

export default JoinNewsLetterForm;
