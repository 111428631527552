import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { findObjName,getCookie,setCookie, manageFile, listFiles} from '../../utils/Functions';
import {
  Container,
  Typography,
  FormGroup,
  Grid,
  Card,
  Button, 
  IconButton,
  Popover ,
  TextField,
  Input,
  InputAdornment,
  Dialog
} from "@mui/material";

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {useDropzone} from 'react-dropzone'
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions/process.actions";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Loading from "../../components/Loading";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }}  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
     //fontSize:13
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    
  },
}));


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#757575',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width:"90%"
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FileManagerDialog = (props) =>  {
	
	const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      
    })
  }, [])
  const {acceptedFiles, isFocused,getRootProps, isDragAccept, isDragReject, getInputProps} = useDropzone(onDrop);
  const dispatch = useDispatch();
 
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	
	const files = acceptedFiles.map(file => (
    <div key={file.path}> 
    	<span>  {file.path} - {file.size} bytes </span>
    </div>
  ));
	
	const remoItems = (event) => {
   	var lengthFiles = acceptedFiles.length
    acceptedFiles.splice(0, acceptedFiles.length)
    
  };
	
	
	const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
	
	const [multipleSelect,setMultipleSelect] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [anchorEl2, setAnchorEl2] = useState(null)
	const [objectName,setObjectName] = useState("")	
	const [newFolderValue,setNewFolderValue] = useState("")
	const [filterValue,setFilterValue] = useState("")
  const [inputNewFile,setInputNewFile] = useState(null)
  const [defaultPath,setDefaultPath] = useState("")
  
  
  const [objImage,setObjImage] = useState({ items:[], route: "x"})    
  const [items, setItems] = useState([]);
  const [formStructure, setFormStructure] = useState([]);
  const [isFaching,setIsFatching]    = useState(false)
  const [resultMsg,setResultMsg]     = useState('');
  
  const localManageFile =async (event) => {
  
		try{
			
			if (event=="uploadFile"){
				handleClose()
			} 
			if (event=="createFolder"){
				handleClose2()
			} 
			setIsFatching(true)
			console.log("ini")
			objImage["newFolderValue"] = newFolderValue
			objImage["filterValue"] = filterValue
			
			var _objImage = await manageFile( event, objImage, acceptedFiles)			
			setObjImage(_objImage)
			
			if (event=="uploadFile"){
				remoItems(event)
			} 
			if (event=="createFolder"){
				setNewFolderValue("")
			}			
			if (_objImage.selectedName && props.onSetFile ){
				
				remoItems(event)
				handleClose()
				if (props.multipleImages){
					props.onSetFile ( {target: { name: props.fieldName, type: _objImage.selectedType,  value: [ {value:_objImage.selected.replace(process.env.REACT_APP_PATH_IMAGES,"")} ] } })
				}else{
					props.onSetFile ( {target: { name: props.fieldName, type: _objImage.selectedType,  value: _objImage.selected.replace(process.env.REACT_APP_PATH_IMAGES,"") } })	
				}
				props.onClose()
			}
			
		}catch(err){
		//	console.log(">>>>>>>>>>",err)
		}finally{
		//	console.log("fin")
			setIsFatching(false)
		}
	} 
	
	const selectMultipleImages =(event) => {
		let items=[]
		for (var i in objImage.items ){
 			if(objImage.items[i].checked){ 
 				 items.push ( {value: objImage.items[i].path.replace(process.env.REACT_APP_PATH_IMAGES,"") } )
 			}
 		} 
 		if (items.length>0 && props.onSetFile ){
			remoItems(event)
			handleClose()
			props.onSetFile ( {target: { name: props.fieldName, type: "multipleimages",  value: items } })
			props.onClose()
		}
			

	}
	const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover' : undefined;
  
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
	const changeNewFolderValue = (event) => {
    setNewFolderValue(event.target.value);
  };
  
  const changeFilterValue = (event) => {
    setFilterValue(event.target.value);
    
    if (event.key === 'Enter') {
      localManageFile(event)
    }
  };
  
  


  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  
  const { storepath, defLang, defConfig } = useSelector((state) => state.Login);
  
  React.useEffect(()=>{
	 	const fetchFn2 = async ()=>{
			setIsFatching(true) 			      
      const formStructure=[]
      var _objImage = await listFiles(storepath,objectName);
      //console.log(_objImage, storepath)
      setDefaultPath(storepath)
			setObjImage(_objImage)
			setFormStructure(formStructure)
    	setIsFatching(false)
    }
 		fetchFn2(); 
	
		 
  },[storepath]) 
	
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg"  fullScreen={fullScreen} >
     	{isFaching ? <Loading />  : ""}
      <Grid container sx={{ mt: 2, mb: 3 }}>
      	<Grid pl={3} item xs={11}  >
        	<Typography variant="h5"
          	 sx={{ 
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "bolder",
              textTransform: "none"
            }}>
            {defConfig[defLang].multimediamanagerlabel}
         </Typography>  
        </Grid>
         
      	<Grid item xs={1} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
					  <CloseIcon />
					</IconButton>
      	</Grid>
      	
      	
      	<Grid item lg={12}  >
        	<Card>
          	<Container style={{ marginTop: 20, marginBottom: 20, minHeight:450 }}>
             	
            	<Grid container spacing={1}>
            	
            		 <Grid item xs={12}  >
				        	<Typography variant="h6"
				          	 sx={{ 
				              fontFamily: "'Open Sans', sans-serif",
				              fontWeight: "bolder",
				              textTransform: "none"
				            }}>
				            {defConfig[defLang].pathlabel}: {"/"+objImage.route.replace (defaultPath, "" )}
				         	</Typography>  
				        </Grid>
            	
	     					<Grid item xs={12}  > 
	     						{objImage.route!= defaultPath &&
	       						<BootstrapTooltip  title="Atras" arrow>
	     								<Button sx={{fontSize: "0.775rem"}}   onClick={() => localManageFile("getParent")}  name="getParent" color="secondary"  variant="contained" startIcon={<ArrowBackIosIcon />}>
							      	</Button>
	   								</BootstrapTooltip >
   								}
   								<BootstrapTooltip  title="Refrescar" arrow>
      							<Button sx={{fontSize: "0.775rem", ml:1}}  onClick={() => localManageFile("refresh")}  name="refresh" color="success"  variant="contained" startIcon={<RefreshIcon />}>
							      </Button>
  								</BootstrapTooltip >
  								
  								{props.lockEdit?"":
	        					<>
	        						<BootstrapTooltip  title="Subir Archivo" arrow>
		      							<Button sx={{fontSize: "0.775rem", ml:1}}   aria-describedby={id} onClick={handleClick}  name="showUploadFile" color="info"  variant="contained" startIcon={<FileUploadIcon />}>
									      </Button>
	    								</BootstrapTooltip >
	    								
	    								 <Popover
									        id={id}
									        open={open}
									        anchorEl={anchorEl}
									        onClose={handleClose}
									       
									        anchorOrigin={{
									          vertical: 'bottom',
									          horizontal: 'left',
									        }}>
									        
									        <section className="dropzone-container">
											     <div {...getRootProps({style})}>
											        <input {...getInputProps()} />
											        <p>Arrastre uno o mas archivos aqu&iacute; o <span style={{ textDecoration:"underline", cursor:"pointer"}}> haga click</span> para elegirlos</p>
											      </div>
											      <aside> 
											         <div className="lista-archivos">{files}</div>
											      </aside>
											      <p style={{textAlign:'right', marginTop: '1rem'}}>
											       {files.length>0? 
											       	<Button  style={{fontSize: "0.775rem"}} onClick={() => localManageFile("uploadFile")} name="uploadFile" color="info"  variant="contained" startIcon={<FileUploadIcon />}>
									      			Subir Archivo(s)
									      			</Button> :
									      		 "" }
											      </p>
											      
											      <p style={{textAlign:'left', marginTop: '1rem', fontWeight: 'bold',fontSize: '0.65rem', fontStyle: 'oblique'	 }}>
											       *Si no eligi&oacute; un directorio, el o los archivos se subir a la carpeta correspondiente al dia actual
											      </p>
											    </section>
									     </Popover>
				           
					          	<BootstrapTooltip  title="Eliminar Archivo(s)" arrow>
		      							<Button sx={{fontSize: "0.775rem", ml:1}} onClick={() => localManageFile("deleteItems")} name="deleteItems" color="error"  variant="contained" startIcon={<DeleteForeverIcon />}>
									      </Button>
	    								</BootstrapTooltip >
    								</>
				          }
  								
  								{multipleSelect?
      				 
		          			<BootstrapTooltip  title="Eliminar Archivos" arrow>
	      							<Button  sx={{fontSize: "0.775rem" , ml:1}} onClick={() => localManageFile("selectMultiple")}  name="selectMultiple" color="info"   variant="contained" startIcon={<DeleteSweepIcon />}>
								      </Button>
								  	</BootstrapTooltip >
		           
			          	:""} 
			          	
			          	<BootstrapTooltip  title="Crear Carpeta" arrow>
      							<Button  sx={{fontSize: "0.775rem" , ml:1}} aria-describedby={id2} onClick={handleClick2}  name="showFolderName" color="primary" variant="contained" startIcon={<CreateNewFolderIcon  />}>
							      </Button>
  								</BootstrapTooltip >
  								
  								{props.multipleImages &&
	  								<BootstrapTooltip  title="Seleccionar Archivos" arrow>
	      							<Button  sx={{fontSize: "0.775rem" , ml:1}} aria-describedby={id2} onClick={selectMultipleImages}  name="selectMultipleImages" color="success" variant="contained" startIcon={<DownloadDoneIcon  />}>
								      </Button>
	  								</BootstrapTooltip >
	  							}
  								<Popover
						        id={id2}
						        open={open2}
						        anchorEl={anchorEl2}
						        onClose={handleClose2}
						       
						        anchorOrigin={{
						          vertical: 'bottom',
						          horizontal: 'left',
						        }}>
						        
					         
	            				<Container sx={{ mt: 2, mb:2, ml:0, mr:0 }} >
	              				<Grid container >
						        			<Grid item xs={12} >
						        			
						        				<FormGroup row>
														  <TextField 
														  	value={newFolderValue+""}
																onChange={changeNewFolderValue} 
														  	size="small" variant="outlined" placeholder="Nueva Carpeta" />
														  <Button 
														  	onClick={() => localManageFile("createFolder")}
														  	disabled={ newFolderValue==""?true:false }
														  	type="button" 
														  	variant="contained" disableElevation={true}>
														   Creaar
														  </Button>
														</FormGroup>
{/*
                  					<input type="text" className={"form-control"}
																	/>
														<button  style={{fontSize: "0.775rem"}} disabled={ newFolderValue==""?true:false } type="button" 
															name="createFolder"   className="btn-secondary btn btn-sm"      
															onClick={() => localManageFile("createFolder")}> <i className="fas fa-folder-plus" /></button>
															*/}
									 				</Grid>
									 			</Grid>
											</Container>
	       						 
									</Popover>

		          		 
  								
								</Grid>	
								 
		          	{/*
      					<Grid item xs={6} md={3} lg={2}>  	
									
		          	</Grid>	
		          	*/}
      					<Grid item xs={12} md={6} lg={5} mt={2} >  	
      						<FormGroup row>
			          		<TextField
			          			 
						          label={defConfig[defLang].searchinputlabel}
						          onKeyDown={changeFilterValue}
						          name={"filterValue"}
						          value={filterValue}
						          size="small"
						          onChange={changeFilterValue}
						          InputProps={{
							          endAdornment:(
						              <InputAdornment position="end"> 
						                  <SearchIcon /> 
						              </InputAdornment>
						            )
						          }}
	      							variant="outlined"
						        />
						        <Button 
									  	onClick={() => localManageFile( {target: {name:"filterValue", value:filterValue }} )}
									  	type="button" 
									  	variant="contained" disableElevation>
									   {defConfig[defLang].searchlabel}
									  </Button> 	
						    	</FormGroup>
       
      					</Grid> 
      					<Grid item xs={12} md={6} lg={7} mt={2} sx={{textAlign:"right"}}>
									<BootstrapTooltip  title={defConfig[defLang].previouslabel} arrow>
	      						<Button disabled={objImage.ContinuationToken==""?true:false}  sx={{fontSize: "0.775rem"}} onClick={() => localManageFile("ContinuationToken")}  name="selectMultiple" color="success" variant="contained" startIcon={<ArrowCircleLeftIcon />}>
								    	 {defConfig[defLang].previouslabel}
								    </Button>
								  </BootstrapTooltip >
								  <BootstrapTooltip  title={defConfig[defLang].nextlabel} arrow>
	      						<Button disabled={objImage.NextContinuationToken==""?true:false}  sx={{fontSize: "0.775rem",ml:1}} onClick={() => localManageFile("NextContinuationToken")}  name="selectMultiple" color="success" variant="contained" endIcon={<ArrowCircleRightIcon />}>
								    	 {defConfig[defLang].nextlabel}
								    </Button> 
								  </BootstrapTooltip >
							 	</Grid>
            	</Grid>
              
              <Grid container spacing={2} sx={{mt:1}}>
	                   
	                   
              	{objImage.items.map((key, index) => {
					          return (
					          	<Grid key={"grid1"+index}  item xs={6} md={3} xl={2}  lg={2} sx={{ display: 'flex', alignItems: 'start',justifyContent:'center' }}  >
												<div key={"div1"+index}  style={{ textAlign: "center" }}>
												 	<LightTooltip key={"LightTooltip"+index}  title={ key.label} followCursor >
														<img 
															key={"img"+index} 
															name={"selectItem_"+index} 
															onClick={ () => localManageFile("selectItem_"+index)} 
															style={{width:"100%" , maxHeight: 140, cursor: "pointer" }}
															src={key.type==="folder"?  "/images/folder.png" : key.path } alt={key.name}
														 />
													</LightTooltip>	 
														 <p key={"p1"+index}>
															<div key={"div2"+index} >{key.type!=="folder"?
																
															 
																  <label key={"label1"+index} style={{ wordBreak: "break-all", fontSize:"0.8rem" }}>
																		<input name={"selectToDelete_"+index}  
																					 checked={ key.checked?true:false}
																					 value = {key.name} key={"check_"+index} 
																					 onChange={() => localManageFile("checkItem_"+index)} 
																					 key={"input"+index}
																					 type="checkbox"/> 
																		{key.label.length<15? key.label: key.label.substr(0,14)+"..." }
																	</label>
															 
																:
																	<label key={"label2"+index} style={{ wordBreak: "break-all", fontSize:"0.8rem" }}>
																		{key.label.length<15? key.label: key.label.substr(0,14)+"..." }
																	</label>
																}
															</div>
														 </p>
														 
												 
												</div>
											</Grid>
					             
					              
					          );
					      })} 
         			</Grid> 
						</Container>
          </Card>
        </Grid>   	
      </Grid>
  </Dialog>
  );
};

FileManagerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FileManagerDialog;
