import React               from "react";
import { Button, Card,TextareaAutosize,  Typography,Collapse ,Chip, ImageList ,Stack ,ImageListItem, Checkbox,FormControlLabel, Divider,Switch, InputAdornment, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add'; 
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import estyled from '@emotion/styled';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { find, getImagePath} from '../../utils/Functions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  minHeight: 40,
  /*height: 40,*/
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DivInputContainer = estyled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 80px;
  border-radius: 5px;
  border: 1px solid #616161;
  background: #efefef;
  margin-top: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
`;

const CompDragFormItem = ({ item, index, masterhandleChange, mastervalue, deleteItem }) => {
	
	const {defLang, defConfig} = useSelector((state) => state.Login);	
	
	const theme = useTheme();
	const showTypeLabel = useMediaQuery(theme.breakpoints.down('md'));
	const [id, setId] = React.useState(0);
	const [name, setName] = React.useState("");
	const [label, setLabel] = React.useState("");
	const [action, setAction] = React.useState("");
	const [type, setType] = React.useState("");
	
	const [color, setColor] = React.useState("");
	const [variant, setVariant] = React.useState("");
	const [values, setValues] = React.useState([]);	
	const [size, setSize] = React.useState({
																	  				xs:"auto",
																	  				lg:"auto"
																	  			});
	const [validations, setValidations] = React.useState({
																									  			required : {status: false, message:""},
																										    	string   : {status: false, message:""},
																										    	number   : {status: false, message:""},
																										    	email    : {status: false, message:""},
																										    	min      : {status: false, message:"", min:"" },
																										    	max      : {status: false, message:"", max:"" }
																									  		});
	
 
	const [apiurl, setApiurl] = React.useState("");
	const [apimethod, setApimethod] = React.useState("");
	const [apidefdata, setApidefdata] = React.useState("");
	const [apitoken, setApitoken] = React.useState("");
	
	const [expanded, setExpanded] = React.useState('');	
	const [getOptionsType, setGetOptionsType] = React.useState('static');
  const changeGetOptionsType = (event, newValue) => {
    setGetOptionsType(newValue);
  };
  
	 
	const handleChange = (event) => {
		 
		let field= event.target.name.split("_")
		let colid = field[0]
		let itemid = field[1]
		let fieldname = field[2]
		if (fieldname=="name"){
			setName(event.target.value)
			masterhandleChange( mastervalue, id, "name", event.target.value )
		}else if (fieldname=="label"){
			setLabel(event.target.value)
			masterhandleChange( mastervalue, id, "label", event.target.value )
		}else if (fieldname=="type"){
			setType(event.target.value)
			masterhandleChange( mastervalue, id, "type", event.target.value )
			
			if  ( event.target.value == "multiple" || event.target.value == "multipleimages" || 
						event.target.value == "multipleautocomplete" || event.target.value == "zzzz"){
				masterhandleChange( mastervalue, id, "value", [] )
			}else{
				masterhandleChange( mastervalue, id, "value", "" )
			}
		}else if (fieldname=="action"){
			setAction(event.target.value)
			masterhandleChange( mastervalue, id, "action", event.target.value )	
		}else if (fieldname=="color"){
			setColor(event.target.value)
			masterhandleChange( mastervalue, id, "color", event.target.value )
		}else if (fieldname=="variant"){
			//console.log("variant")
			setVariant(event.target.value)
			masterhandleChange( mastervalue, id, "variant", event.target.value )
		}else if (fieldname=="apiurl"){
			setApiurl(event.target.value)
			masterhandleChange( mastervalue, id, "apiurl", event.target.value )
		}else if (fieldname=="apimethod"){
			setApimethod(event.target.value)
			masterhandleChange( mastervalue, id, "apimethod", event.target.value )
		}else if (fieldname=="apidefdata"){
			setApidefdata(event.target.value)
			masterhandleChange( mastervalue, id, "apidefdata", event.target.value )
		}else if (fieldname=="apitoken"){
			setApitoken(event.target.value)
			masterhandleChange( mastervalue, id, "apitoken", event.target.value )
		}else if (fieldname =="checked"){
			let subField = field[3]
			let _validations= validations
			validations[subField]["status"] = !validations[subField]["status"]
			if (!validations[subField]["status"]){
				validations[subField]["message"] = ""
				
			}
			setApitoken(_validations)
			masterhandleChange( mastervalue, id, "validations", _validations )
		}else if (fieldname =="validations"){
			let subField = field[3]
			
			let _validations= validations
			if ( field.length>4){
				_validations[subField][field[4]] = event.target.value	
			}else{
				_validations[subField]["message"] = event.target.value
			}			
			setApitoken(_validations)
			masterhandleChange( mastervalue, id, "validations", _validations )
		}else if (fieldname =="size"){
			let subField = field[3]
			let _size= size
		 	_size[subField] = event.target.value			 
			setSize(_size)
			masterhandleChange( mastervalue, id, "size", _size )
			
		}
		
	};
	const setPanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
	const addNewoption= (event) => {
   
  	let _values = values
  	let newId=0;
  	_values.map(function (key) {
  		if (newId< key.id){
  			newId=key.id
  		}
  	}); 
  	newId++
  	let newItem ={
			value: "",
			text: "",
			id: newId, 	
		  }
  	_values.push(newItem)
  	setValues(_values)
  	masterhandleChange( mastervalue, id, "values",_values )
  };  
  
  const handleChangeOption = (event) => {
		let field     = event.target.name.split("_")
		let colid     = field[0]
		let itemid    = field[1]
		let optionindex   = field[2]
		let fieldname = field[3]
		let _values = values

		_values[parseInt(optionindex)][fieldname] = event.target.value
		setValues(_values)
		masterhandleChange( mastervalue, id, "values",_values )		
	};

	React.useEffect(()=>{
		
	 		setId(item.id)
			setName(item.name)
			setLabel(item.label)
			setType(item.type)
			setSize(item.size)
			setValidations(item.validations)
			setValues(item.values)
			setVariant(item.variant)
			setColor(item.color)
			setAction(item.action)
			
			setApiurl(item.apiurl)
			setApimethod(item.apimethod)
			setApidefdata(item.apidefdata)
			setApitoken(item.apitoken)
			
			if (item.apiurl!="" ){				
				setGetOptionsType( "api")
			}
			
	 },[item]) 
	
	const [minimize, setMinimize] = React.useState(false);

  const minimizeField= () => {
    setMinimize((minimize) => !minimize);
  };
  	 
  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
        	<Collapse
        		sx={{ borderRadius: !minimize? "5px":"0",
  								borderBottom: !minimize?"1px solid #616161" :"0",
   							}}
        		 in={minimize} collapsedSize={120}>
	          <DivInputContainer>
	         
	          	<Grid container   sx={{ pb:1} } spacing={2}>
	          		<Grid item xs={2} mt={1} >
	          			<IconButton style={{
	          				
	          				marginTop: 4,
									//	marginLeft: -8,
										border: "1px solid #616161",
										paddingTop: 0,
										width: 26,
										color:"#616161",
										borderRadius: 5,
										fontWeight:"bold",
										height: 26,
										fontSize: 18,
										paddingTop: 6

	          				 }} onClick={ ()=>{minimizeField() }}  aria-label="minimize">
								  	{minimize?
								  		<>-</>
								  	:
								  		<>+</>
								  	}
									</IconButton>
	          		</Grid>
	          		 
	          		<Grid item xs={10} mt={1}  sx={{textAlign: "right"}} >
	          		{!showTypeLabel && <Chip label={"< "+type+" >"} color="secondary"  /> }
	          			<IconButton onClick={ ()=>{deleteItem( mastervalue+"_"+item.id+"_delete" ) }}  aria-label="move" color="error">
								  	<DeleteIcon />
									</IconButton>
			          	<IconButton  aria-label="move" color="primary">
								  	<ControlCameraIcon />
									</IconButton>
								</Grid>	
								<Grid style={{paddingTop:0}} pt={0} item xs={12} >
									<Divider/>
								</Grid>		
	          		<Grid item xs={12}>
	          			<TextField
	                     	key={"name"+index}
	                      fullWidth
	                      size="small"
	                    	label={defConfig[defLang].fieldnamelabel}
	                      value={name}
	                      name={mastervalue+"_"+item.id+"_name"}
	                      onChange={handleChange}
	                    />
	              </Grid>
	          		
	            	
	              <Grid item xs={6}>
	          			<TextField
	                     	key={"label"+index}
	                      fullWidth
	                      size="small"
	                    	label={defConfig[defLang].fieldlabellabel}
	                      value={label}
	                      name={mastervalue+"_"+item.id+"_label"}
	                      onChange={handleChange}
	                    />
	              </Grid>
	              <Grid item xs={6}>
		              <FormControl fullWidth>
										<InputLabel style={{ top: type==""?-6: 0 }}>{defConfig[defLang].fieldtypelabel}</InputLabel>
		          			<Select
									    key={"type"+index}
		                  name={mastervalue+"_"+item.id+"_type"}
									    value={type}
									    size="small"
									    label={defConfig[defLang].fieldtypelabel}
									    onChange={handleChange}>
									    	<MenuItem value="text">text</MenuItem>
											<MenuItem value="numeric">numeric</MenuItem>
									    	<MenuItem value="textarea">textarea</MenuItem>
									    	<MenuItem value="hidden">hidden</MenuItem>
									    	<MenuItem value="password">password</MenuItem>
									    	<MenuItem value="select">select</MenuItem>
									    	<MenuItem value="autocomplete">autocomplete</MenuItem>
									    	<MenuItem value="multiple">multiple</MenuItem>
									    	<MenuItem value="multipleautocomplete">multiple autocomplete</MenuItem>
									    	<MenuItem value="radiobuttonh">Horizontal Radio button</MenuItem>
									    	<MenuItem value="radiobuttonv">Vertical Radio button</MenuItem>
									    	<MenuItem value="checkbox">checkbox</MenuItem>
											<MenuItem value="switch">switch</MenuItem>
									    	<MenuItem value="date">date</MenuItem>
									    	<MenuItem value="title">title</MenuItem>
									    	<MenuItem value="image">image</MenuItem>
									    	<MenuItem value="staticimage">Static image</MenuItem>
									    	<MenuItem value="multipleimages">multipleimages</MenuItem>
									    	<MenuItem value="space">space</MenuItem>
									    	<MenuItem value="orderitems">order items</MenuItem>
									    	<MenuItem value="coupons">coupons</MenuItem>
									    	<MenuItem value="scriptarea">script</MenuItem>
									  </Select>
								  </FormControl>
	              </Grid>
	              
	              <Grid item xs={6}>
		              <FormControl fullWidth>
										<InputLabel style={{ top: type==""?-6: 0 }}>{defConfig[defLang].colsizemobilelabel}*</InputLabel>
		          			<Select
									    key={"size_xs"+index}
		                  name={mastervalue+"_"+item.id+"_size_xs"}
									    value={size.xs}
									    size="small"
									    label={defConfig[defLang].colsizemobilelabel}
									    onChange={handleChange}>
									    	<MenuItem value="auto">auto</MenuItem>
									    	<MenuItem value="12">col-12</MenuItem>
									    	<MenuItem value="8">col-8</MenuItem>
									    	<MenuItem value="6">col-6</MenuItem>
									    	<MenuItem value="4">col-4</MenuItem>
									    	<MenuItem value="3">col-3</MenuItem>
									    	<MenuItem value="2">col-2</MenuItem>
									  </Select>
								  </FormControl>
	              </Grid>
	              <Grid item xs={6}>
		              <FormControl fullWidth>
										<InputLabel style={{ top: type==""?-6: 0 }}>{defConfig[defLang].colsizeweblabel}*</InputLabel>
		          			<Select
									    key={"size_lg"+index}
		                  name={mastervalue+"_"+item.id+"_size_lg"}
									    value={size.lg}
									    size="small"
									    label={defConfig[defLang].colsizeweblabel}
									    onChange={handleChange}>
									    	<MenuItem value="auto">auto</MenuItem>
									    	<MenuItem value="12">col-12</MenuItem>
									    	<MenuItem value="8">col-8</MenuItem>
									    	<MenuItem value="6">col-6</MenuItem>
									    	<MenuItem value="4">col-4</MenuItem>
									    	<MenuItem value="3">col-3</MenuItem>
									    	<MenuItem value="2">col-2</MenuItem>
									  </Select>
								  </FormControl>
	              </Grid>
	              
	              <Grid item xs={12}>
	              
	             	 <TextareaAutosize 
				                     		maxRows={20}
				                     		minRows={3}
				                     		style={{ width: "99%" }}
					                     	key={"action"+index}
					                      fullWidth
					                      multiline 					                       
					                      label={defConfig[defLang].fieldactionlabel}					                      
					                      name={mastervalue+"_"+item.id+"_action"}
					                      value={action}
					                      onChange={handleChange}/>
					                      
	          		 
	              </Grid>
	              
	              <Grid item xs={12}>
	              
	              	<Accordion  expanded={expanded === 'panel0'} onChange={setPanel('panel0')}>
						        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
						          <Typography>{defConfig[defLang].stylelabel}</Typography>
						        </AccordionSummary>
						        <AccordionDetails>
						        	<Grid container mt={2} spacing={1} >
	          						<Grid item xs={6} sx={{ display:"flex", alignItems:"end"}}>
		          						<FormControl fullWidth>
														<InputLabel style={{ top: type==""?-6: 0 }}>{defConfig[defLang].fontsizelabel}</InputLabel>
						          			<Select
													    key={"variant"+index}
						                  name={mastervalue+"_"+item.id+"_variant"}
													    value={variant}
													    size="small"
													    label={defConfig[defLang].fontsizelabel}
													    onChange={handleChange}>
													    	<MenuItem value="body">body</MenuItem>
													    	<MenuItem value="h1">h1</MenuItem>
													    	<MenuItem value="h2">h2</MenuItem>
													    	<MenuItem value="h3">h3</MenuItem>
													    	<MenuItem value="h4">h4</MenuItem>
													    	<MenuItem value="h5">h5</MenuItem>
													    	<MenuItem value="h6">h6</MenuItem>
													  </Select>
												  </FormControl>
						         		</Grid>
						         		<Grid item xs={6} sx={{ display:"flex", alignItems:"end"}}>
		          						<FormControl fullWidth>
														<InputLabel style={{ top: type==""?-6: 0 }}>{defConfig[defLang].fontcolorlabel}</InputLabel>
						          			<Select
													    key={"color"+index}
						                  name={mastervalue+"_"+item.id+"_color"}
													    value={color}
													    size="small"
													    label={defConfig[defLang].fontcolorlabel}
													    onChange={handleChange}>
													    	<MenuItem value="primary">primary</MenuItem>
													    	<MenuItem value="secondary">secondary</MenuItem>
													    	<MenuItem value="success">success</MenuItem>
													    	<MenuItem value="warning">warning</MenuItem>
													    	<MenuItem value="info">info</MenuItem>
													    	<MenuItem value="error">error</MenuItem>
													  </Select>
												  </FormControl>
						         		</Grid>
						         	</Grid> 
						        </AccordionDetails>
						      </Accordion>
						        
	              
									<Accordion  expanded={expanded === 'panel1'} onChange={setPanel('panel1')}>
						        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
						          <Typography>{defConfig[defLang].validationsabel}</Typography>
						        </AccordionSummary>
						        <AccordionDetails>
						          
						          <Grid container mt={2} spacing={1} >
	          						<Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
		          						<FormControlLabel
										          control={
										            <Switch
										          key={"swvalidations_required"+index}  
		          								checked={validations.required.status}
		          								name={mastervalue+"_"+item.id+"_checked_required"}
		      										onChange={handleChange}
												      color="success" 
												      inputProps={{ 'aria-label': 'validations_required' }}
												    />
										          }
										          label={defConfig[defLang].fieldrequiredlabel}
									        />
						         		</Grid>
						         		
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}} >
					          			<TextField
					                     	key={"validations_required"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.required.message}
					                      name={mastervalue+"_"+item.id+"_validations_required"}
					                      disabled={!validations.required.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					              
					              <Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
					              	<FormControlLabel
									          control={
									            <Switch
									          key={"swvalidations_string"+index}  
	          								checked={validations.string.status}
	          								name={mastervalue+"_"+item.id+"_checked_string"}
	      										onChange={handleChange}
											      color="success" 
											      inputProps={{ 'aria-label': 'validations_string' }}
											    />
									          }
									          label={defConfig[defLang].justtextlabel}
									        />
						         		</Grid>
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}}>
					          			<TextField
					                     	key={"validations_string"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.string.message}
					                      name={mastervalue+"_"+item.id+"_validations_string"}
					                      disabled={!validations.string.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					              <Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
					              	<FormControlLabel
									          control={
									            <Switch
									            	key={"swvalidations_number"+index}  
			          								checked={validations.number.status}
			          								name={mastervalue+"_"+item.id+"_checked_number"}
			      										onChange={handleChange}
													      color="success" 
													      inputProps={{ 'aria-label': 'validations_number' }}
													    />
									          }
									          label={defConfig[defLang].justnumberlabel}
									        />
									        
						         		</Grid>
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}}>
					          			<TextField
					                     	key={"validations_number"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.number.message}
					                      name={mastervalue+"_"+item.id+"_validations_number"}
					                      disabled={!validations.number.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					              
					              <Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
					              	<FormControlLabel
									          control={
									            <Switch
									            	key={"swvalidations_checked"+index}  
			          								checked={validations.email.status}
			          								name={mastervalue+"_"+item.id+"_checked_email"}
			      										onChange={handleChange}
													      color="success" 
													      inputProps={{ 'aria-label': 'validations_email' }}
													    />
									          }
									          label={defConfig[defLang].justemaillabel}
									        />								        
						         		</Grid>
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}}>
					          			<TextField
					                     	key={"validations_email"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.email.message}
					                      name={mastervalue+"_"+item.id+"_validations_email"}
					                      disabled={!validations.email.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					              
					              <Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
					              	<FormControlLabel
									          control={
									            <Switch
									            	key={"swvalidations_min"+index}  
			          								checked={validations.min.status}
			          								name={mastervalue+"_"+item.id+"_checked_min"}
			      										onChange={handleChange}
													      color="success" 
													      inputProps={{ 'aria-label': 'validations_min' }}
													    />
									          }
									          label={defConfig[defLang].minvaluelabel}
									        />
									        
						         		</Grid>
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}}>
					          			<TextField
					                     	key={"validations_min"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].minvaluelabel}
					                      value={validations.min.min}
					                      name={mastervalue+"_"+item.id+"_validations_min_min"}
					                      disabled={!validations.min.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					                <TextField
					                     	key={"validations_min_2"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.min.message}
					                      name={mastervalue+"_"+item.id+"_validations_min_message"}
					                      disabled={!validations.min.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					              <Grid item xs={12} sx={{ display:"flex", alignItems:"end"}}>
					              	<FormControlLabel
									          control={
									            <Switch
									            	key={"swvalidations_max"+index}  
			          								checked={validations.max.status}
			          								name={mastervalue+"_"+item.id+"_checked_max"}
			      										onChange={handleChange}
													      color="success" 
													      inputProps={{ 'aria-label': 'validations_max' }}
													    />
									          }
									          label={defConfig[defLang].maxvaluelabel}
									        />
									        
						         		</Grid>
						         		<Grid item xs={12} sx={{ display:"flex", alignItems:"start" , marginTop:-1}}>
					          			<TextField
					                     	key={"validations_max"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].maxvaluelabel}
					                      value={validations.max.max}
					                      name={mastervalue+"_"+item.id+"_validations_max_max"}
					                      disabled={!validations.max.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              	 <TextField
					                     	key={"validations_max_2"+index}
					                      fullWidth
					                      size="small"
					                    	label={defConfig[defLang].alertmessagelabel}
					                      value={validations.max.message}
					                      name={mastervalue+"_"+item.id+"_validations_max_message"}
					                      disabled={!validations.max.status}
					                      onChange={handleChange}
					                      variant="standard"
					                    />
					              </Grid>
					            </Grid> 
						        </AccordionDetails>
						      </Accordion>
						      {(type=="select" || type=="radiobuttonv" || type=="radiobuttonh" || type=="multiple"  || type=="autocomplete" || type=="multipleautocomplete") &&
							      <Accordion expanded={expanded === 'panel2'} onChange={setPanel('panel2')}>
							        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
							          <Typography>{defConfig[defLang].optionslabel}</Typography>
							        </AccordionSummary>
							        <AccordionDetails>
							           <Box sx={{ width: '100%' }}>
		     									 <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									           <Tabs
											        value={getOptionsType}
											        onChange={changeGetOptionsType}
											        textColor="primary"
											        indicatorColor="primary"
											        aria-label="values"
											        centered
											      >
											        <Tab value="static" label={defConfig[defLang].staticdatalabel} />
											        <Tab value="api" label={defConfig[defLang].dynamiclabel} /> 
											      </Tabs>
											    </Box> 
											      {getOptionsType=="static"?
											        	<Box sx={{ width: '100%', typography: 'body1', mt:2 }}>
											        		<Grid container spacing={1}>
												        		{values.length==0 &&
													        		<Grid item xs={12} sx={{textAlign: "right"}} >
														      			<IconButton onClick={addNewoption}  aria-label="move" color="success">
																			  	<AddIcon /> 
																				</IconButton>
															      	</Grid>
															      }
												        		{values.map((opt, indexOption) => {
												        			return (
													        			<>
															           	<Grid item xs={5}>
														          			<TextField
														                     	key={"text"+indexOption}
														                      fullWidth
														                      size="small"
														                    	label={defConfig[defLang].labellabel}
														                      value={opt.text}
														                      name= {mastervalue+"_"+item.id+"_"+indexOption+"_text"}
														                      onChange={handleChangeOption}
														                    />
														              </Grid>
														              <Grid item xs={5}>
														          			<TextField
														                     	key={"value"+indexOption}
														                      fullWidth
														                      size="small"
														                    	label={defConfig[defLang].valuelabel}
														                      value={opt.value}
														                      name={mastervalue+"_"+item.id+"_"+indexOption+"_value"}
														                      onChange={handleChangeOption}
														                    />
														              </Grid>
														              
														              <Grid item xs={1}>
																      			<IconButton onClick={ ()=>{deleteItem( mastervalue+"_"+item.id+"_delete" ) }}  aria-label="move" color="error">
																					  	<DeleteIcon />
																						</IconButton>
														              </Grid>
														              {indexOption == 0 &&
															              <Grid item xs={1}>
																	      			<IconButton onClick={addNewoption}  aria-label="move" color="success">
																						  	<AddIcon /> 
																							</IconButton>
															              </Grid>
														              }
												             		</>
											              	);
							          						})}
																	</Grid>
											        	</Box>
											        :
											        	<Box sx={{ width: '100%', typography: 'body1', mt:2 }}>
											        		<Grid container spacing={1}>
												           	<Grid item xs={12}>
											          			<TextField
											                     	key={"apiurl"+index}
											                      fullWidth
											                      size="small"
											                    	label={defConfig[defLang].urlapilabel}
											                      value={apiurl}
											                      name={mastervalue+"_"+item.id+"_apiurl"}
											                      onChange={handleChange}
											                    />
											              </Grid>
											              <Grid item xs={12}>
											          			<FormControl fullWidth>
																				<InputLabel style={{ top: type==""?-6: 0 }}>Metodo</InputLabel>
												          			<Select
																			    key={"apimethod"+index}
												                  name={mastervalue+"_"+item.id+"_apimethod"}
																			    value={apimethod}
																			    size="small"
																			    label={defConfig[defLang].methodlabel}
																			    onChange={handleChange}>
																			    	<MenuItem value="POST">POST</MenuItem>
																			    	<MenuItem value="GET">GET</MenuItem>
																			    	<MenuItem value="PUT">PUT</MenuItem>
																			    	<MenuItem value="DELETE">DELETE</MenuItem>
																			    	<MenuItem value="PATCH">PATCH</MenuItem>
																			  </Select>
																		  </FormControl>
											              </Grid>
											              <Grid item xs={12}>
											          			<TextField
											                     	key={"apidefdata"+index}
											                      fullWidth
											                      size="small"
											                    	label={defConfig[defLang].datatosendlabel}
											                      value={apidefdata}
											                      name={mastervalue+"_"+item.id+"_apidefdata"}
											                      onChange={handleChange}
											                    />
											              </Grid>
											              <Grid item xs={12}>
											          			<TextField
											                     	key={"apitoken"+index}
											                      fullWidth
											                      size="small"
											                    	label={defConfig[defLang].tokenlabel}
											                      value={apitoken}
											                      name={mastervalue+"_"+item.id+"_apitoken"}
											                      onChange={handleChange}
											                    />
											              </Grid>
																	</Grid>
											        	</Box>
											      	}
							          </Box> 
							        </AccordionDetails>
						     		</Accordion>
	              	}
	              </Grid>	              
	          	</Grid>	          	
	          </DivInputContainer>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};
CompDragFormItem.propTypes = {};

export default CompDragFormItem;