import { combineReducers } from "redux";
import LoginReducer from "./user.reducer";
import ProcessReducer from "./process.reducer";

const appReducer = combineReducers({
  Login: LoginReducer,
  Process: ProcessReducer,
});

export default (state, action) => appReducer(state, action);
