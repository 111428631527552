import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Container,
	Typography,
	FormGroup,
	Grid,
	Card,
	Button, 
	IconButton,
	Popover ,
	TextField,
	Input,
	Dialog,
	InputAdornment
  } from "@mui/material";
import {useDropzone} from 'react-dropzone'
import ApiClient from "../../api/ApiClient";
import EndPoint from "../../api/Endpoints";
import Table from "../../components/table/table";
import TableV1 from "../../components/table/tableV1";
import Filters from "../../components/table/filter";
import Loading from "../../components/Loading";
import { findObjName,getCookie,setCookie, manageFile, listFiles, searchHandler} from '../../utils/Functions';
import SearchIcon from '@mui/icons-material/Search'; 
import * as Actions from "../../redux/actions/process.actions";
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#757575',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	width:"90%"
};

const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const ListData = (props) => {
	const navigate = useNavigate();
	const { defConfig, params, defLang, activeForm } = useSelector((state) => state.Login);
	const { componentConfig, loadid } = useSelector((state) => state.Process);
	
  const dispatch = useDispatch();
  const [componentOptions,setComponentOptions]    = React.useState([] )
  const [addNewOption,setAddNewOption]    = React.useState(false)
  const [bulkLoadOption,setbulkLoadOption]    = React.useState(false)
  const [filterConfig,setFilterConfig]    = React.useState({ })
  const [filterValues,setFilterValues]    = React.useState({ })
  const [originalTableData,setOriginalTableData]    = React.useState([])
  const [tableData,setTableData]    = React.useState([])
  const [hideFilter,setHideFilter]    = React.useState(true)
  const [isLoading,setIsLoading]    = React.useState(false)
  const [showMore,setShowMore]    = React.useState(false)
  const onDrop = useCallback((acceptedFiles) => {

    acceptedFiles.forEach((file) => {
      
    })
  }, [])
  const {acceptedFiles, isFocused,getRootProps, isDragAccept, isDragReject, getInputProps} = useDropzone(onDrop);
 
	let  { componentName } = useParams();	

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	  }), [
		isFocused,
		isDragAccept,
		isDragReject
	  ]);

 	React.useEffect(()=>{
 	 
 		if (componentConfig.initial ){
 			console.log("reload", componentName)
 			dispatch(Actions.getComponentConfig(componentName, "report"))	
 		}else{
 			
 			//console.log(componentConfig)
 			
			setOriginalTableData( componentConfig.rows)
			setTableData( componentConfig.rows)
			let actions=[]
			componentConfig.actions.map(function (key) {
				if (key.name=="create"){
					setAddNewOption(true)
				}
				actions.push(key.name)
			}); 

			//console.log("componentConfig.actions", componentConfig.actions)


			let create = false
			let bulk   = false

 			componentConfig.actions.map(function (key) {
 				if (key.name=="create"){
					create=true
					setAddNewOption(true)
 				}
				if (key.name=="bulk-load"){					
					bulk=true
					setbulkLoadOption(true)
				}
 				actions.push(key.name)
			}); 
			
			setComponentOptions(actions)
			if (create){
				setAddNewOption(true)
			}else{
				setAddNewOption(false)
			}

			if (bulk){
				setbulkLoadOption(true)
			}else{
				setbulkLoadOption(false)
			}

			
			if (Object.keys(componentConfig?.filters).length===0){

				setHideFilter(true)
			}else{
				setFiltersValues()
			}
			
			setFilterConfig(componentConfig.filters)

 		}

		if(loadid>0){
			setFilterValue(loadid)
		}
  	//console.log(componentConfig)
  },[componentConfig,loadid])

  const setFiltersValues = async () => {
	let _filterValues={ }
	let filters={}

	filters = componentConfig?.filters
	
	Object.keys(componentConfig.filters).map(function (key) {
		_filterValues[key] = componentConfig.filters[key].value? componentConfig.filters[key].value:""
		if ( key.indexOf("FROMDATE") >=0) {
			var fromDate = new Date()
			fromDate.setDate(new Date().getDate() - 365);
			filters[key]["value"] = fromDate
			_filterValues[key] = fromDate
		}

		if ( key.indexOf("TODATE") >=0) {
			var toDate = new Date()				
			filters[key]["value"] = toDate
			_filterValues[key] = toDate
		}
	}); 
	 
	setFilterValues(_filterValues)
	setHideFilter(false)
  };

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const files = acceptedFiles.map(file => (
    <div key={file.path}> 
    	<span>  {file.path} - {file.size} bytes </span>
    </div>
  ));
  const handleClose = () => {
    setAnchorEl(null);
  };

  const remoItems = (event) => {
	var lengthFiles = acceptedFiles.length
	acceptedFiles.splice(0, acceptedFiles.length)
  };
  const [isFaching,setIsFatching]    = useState(false)
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

	const localManageFile =async (event) => {
  
		try{
			
			if(acceptedFiles[0].type ==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || acceptedFiles[0].path.indexOf(".xlsx")>0|| acceptedFiles[0].path.indexOf(".xls")>0 ){
				const formData = new FormData();
				formData.append(
					"filename",
					acceptedFiles[0],
					acceptedFiles[0].name
				);
				formData.append("objectType", componentName);	
			

				dispatch(Actions.bulkData( componentName, formData  ))

				//const response = await ApiClient.doPost(EndPoint["bulkData"], formData);
				//console.log(response)
				remoItems(event)
				handleClose()
			}else{
				console.log("invalid file")
			}
			
			
			
		}catch(err){
		//	console.log(">>>>>>>>>>",err)
		}finally{
		//	console.log("fin")
			//setIsFatching(false)
		}
	}
	const [filterValue,setFilterValue] = useState("")
	const changeFilterValue = (event) => {
		setFilterValue(event.target.value);
		
		if (event.key === 'Enter') {
			searchLoad(event)
		}
	};
	const [rowsBulkData, setRowsBulkData] = React.useState([]);
	const [colsBulkData, colsRowsBulkData] = React.useState([

		{id: "LOADID", label:  "Id Carga"},
		{id: "DEST", label:  "Tipo Carga"},
		{id: "INSERT_DATE", label:  "Fecha Registro"},
		{id: "INSERT_USER", label:  "Usuario"},
		{id: "STATUS", label:  "Estado"},
		{id: "RESPONSE", label:  "Respuesta"},
		{id: "FIELD0", label:  "FIELD0"},
		{id: "FIELD1", label:  "FIELD1"},
		{id: "FIELD2", label:  "FIELD2"},
		{id: "FIELD3", label:  "FIELD3"},
		{id: "FIELD4", label:  "FIELD4"},
		{id: "FIELD5", label:  "FIELD5"},
		{id: "FIELD6", label:  "FIELD6"},
		{id: "FIELD7", label:  "FIELD7"},
		{id: "FIELD8", label:  "FIELD8"},
		{id: "FIELD9", label:  "FIELD9"},
		{id: "FIELD10", label:  "FIELD10"},
		{id: "FIELD11", label:  "FIELD11"},
		{id: "FIELD12", label:  "FIELD12"},
		{id: "FIELD13", label:  "FIELD13"},
		{id: "FIELD14", label:  "FIELD14"},
		{id: "FIELD15", label:  "FIELD15"},
		{id: "FIELD16", label:  "FIELD16"},
		{id: "FIELD17", label:  "FIELD17"},
		{id: "FIELD18", label:  "FIELD18"},
		{id: "FIELD19", label:  "FIELD19"},
		{id: "FIELD20", label:  "FIELD20"},
		{id: "FIELD21", label:  "FIELD21"},
		{id: "FIELD22", label:  "FIELD22"},
		{id: "FIELD23", label:  "FIELD23"},
		{id: "FIELD24", label:  "FIELD24"},
		{id: "FIELD25", label:  "FIELD25"},
		{id: "FIELD26", label:  "FIELD26"},
		{id: "FIELD27", label:  "FIELD27"},
		{id: "FIELD28", label:  "FIELD28"},
		{id: "FIELD29", label:  "FIELD29"},
		{id: "FIELD30", label:  "FIELD30"},


	]);
	const searchLoad = async (event) => {

		try{
			
			setIsFatching(true)	 	
			const response = await ApiClient.doPost(EndPoint["getBulkData"], {id: filterValue});
			//console.log(response)
			if (response.status){
				setOpenLoadsDialog(true);
				setRowsBulkData(response.data)
			}

			
		 }catch(err){
			//console.log(">>>>>>>>>>",err)
		}finally{
			//console.log("fin")
			setIsFatching(false)
		}
	};

	const [openLoadsDialog, setOpenLoadsDialog] = React.useState(false);

	const _searchHandler = async(values) => {

	
		setTableData( [])
		setIsLoading(true)
	
	
	 
		if (values!=="refresh"){		
			let resp = await searchHandler( componentConfig.getAllDataApiName,   values, originalTableData );
			setTableData( resp.tableData)
		
		}else{
		 
			setTableData( originalTableData)
			let filters = componentConfig?.filters
			let _filterValues={ }
			Object.keys(componentConfig.filters).map(function (key) {
				_filterValues[key] = componentConfig.filters[key].value? componentConfig.filters[key].value:""
	 
				if ( key.indexOf("FROMDATE") >=0) {
					var fromDate = new Date()
					fromDate.setDate(new Date().getDate() - 7);
					filters[key]["value"] = fromDate
					_filterValues[key] = fromDate
				}
	
				if ( key.indexOf("TODATE") >=0) { 
					var toDate = new Date()				
					filters[key]["value"] = toDate
					_filterValues[key] = toDate
				}
			}); 
			 
			 
			setFilterValues(_filterValues)
			
		}	 
		setTimeout(() => {
			setIsLoading(false)
		}, 500);		
	  } 
	 
	  const [showB,setShowB]    = React.useState(false)
	
	  const _toggleFilter = async(values) => {
		setHideFilter(!hideFilter)
		setShowB(!showB)
	  }

  return (
	<>
		{isFaching && <Loading/> }
		{isLoading &&  <Loading/> }

		 
		
		<Grid className="fixed-container" style={{
		top: 0,
		maxWidth:1200		
		}}  
		container >

			<Grid container spacing={1} sx={{px:2, mt:2 }}>
				<Grid >
					{componentConfig.name &&
					<Typography variant="h3" color="primary"
							style={{                 
								fontWeight: "bolder",
								textTransform: "capitalize",

							}}
					> Reporte de {componentConfig?.name} </Typography>
					}
				</Grid>
			</Grid>
		

	  

			<Grid container spacing={0} mt={3} className="body-container" sx={{minHeight: "50vh"}}>

    		{componentConfig.columns &&
    			<> 
					{!hideFilter &&
						<Grid item lg={2} xs={12} md={3} mb={1} pr={{ sx:0, md:1}} >
							<Filters  toggleFilter={ _toggleFilter } filterConfig={filterConfig} initialValues={filterValues} showMore={showMore} searchHandler={_searchHandler}/>
						</Grid>
					}
					<Grid item lg={hideFilter?12:10} xs={12} md={hideFilter?12:9}>

					{tableData.length>0 &&
						<div style={{width:"100%", textAlign:"right"}}>
							{showB &&
								<button type="button" onClick={_toggleFilter} className="btn btn-secondary mb-2" name="set" > Mostrar Fitros</button>	
							}
							<ExcelFile filename={"export-"+componentConfig?.name} element={         
								<button type="button" className="btn btm-sm btn-export mb-2" name="set" > Exportar</button>
							}>
							<ExcelSheet data={tableData} name="Hoja 1">	

								{componentConfig.columns.map((column, indexCell) => {
									return(
										<ExcelColumn label={column.label} value={column.id}/> 
									)
									
								})}
								
							</ExcelSheet>								
							</ExcelFile> 
					
						</div>
					}
					
						<Table rows={tableData} writable={componentConfig.writable} columns={componentConfig.columns} componentName={componentName} componentOptions={componentOptions} />
					
					</Grid>
				</>
	    	}
    	</Grid>
    </Grid>
	</>
  );
}

ListData.propTypes = {
//  window: ListData.func,
};

export default ListData;
