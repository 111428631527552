import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getUserRol,hasChildren} from '../../utils/Functions';
import * as ProcessActions from "../../redux/actions/process.actions";
import {
   
  MenuList,
  MenuItem,
  Toolbar,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Autocomplete,
  Avatar,
  Stack,
  Collapse
  
} from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SidebarNav = ( props ) => {
	const dispatch = useDispatch();
	const { user, requesting, defLang, defConfig, params, username } = useSelector((state) => state.Login);
	const navigate = useNavigate();
	const [address, setAddress] = useState([{ label: 'The Shawshank Redemption', year: 1994 },
  																				{ label: 'The Godfather', year: 1972 },]);
	const handleClick = (event) => {
		if(event.link.indexOf("manage")>=0 || event.link.indexOf("report")>=0){
			dispatch(ProcessActions.getComponentConfig(event.code, event.type))
		}
  	navigate(event.link)
  };
  																		
  return (
    <>
      <MenuList>
        <MenuItem>
          <ListItem sx={{display:"flex", justifyContent:"center", mt:0 }}>
	          <Avatar
			        alt={username}
			        src="/images/users/user.png"
			        sx={{ maWidth: 142, width: "100%", height: "100%" }}
			      />
	        </ListItem>
        </MenuItem>        
        <MenuItem> 
       	  	<Typography variant="h6" sx={{ pl:2 ,mb:0}} fontWeight="bolder">
            	{username}
            </Typography> 
        </MenuItem>
      </MenuList>
    
      <List
		      sx={{ width: '100%',
			  overflow: "hidden auto",
			  maxHeight: "65vh"
			  
			}}
		      component="nav" 
		    >
	        {props.menu.map((item, key) => {		        	
						const MenuItem = ({ item }) => {
						  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
						  return <Component  item={item} />;
						};
						const SingleLevel = ({ item }) => {
						  return (
						    <ListItemButton onClick={ ()=>{  handleClick(item) } } >
						     
						      <ListItemText primary={item.title} />
						    </ListItemButton>
						  );
						};
						const MultiLevel = ({ item }) => {
						  const { items: children } = item;
						  const [open, setOpen] = useState(false);
						  const handleClick = () => {
						    setOpen((prev) => !prev);
						  };
						  return (
						    <>
						      <ListItemButton style={{ borderBottom: "1px solid #444d62" }}  onClick={handleClick}>
						        
						        <ListItemText primary={item.title} />
						        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						      </ListItemButton>
						      <Collapse in={open} timeout="auto" unmountOnExit>
						        <List sx={{pl:1}} component="div" >
						          {children.map((child, key) => (
						            <MenuItem   key={key} item={child} />
						          ))}
						        </List>
						      </Collapse>
						    </>
						  );
						};
	        	return (
	        		<MenuItem key={key} item={item} />
		      	);
	        })}
      </List>  
    </>
  );

}

export default SidebarNav;
