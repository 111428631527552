import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  Radio,
  RadioGroup,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Actions from "../../redux/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import Captcha from 'demos-react-captcha';
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const numberRegExp = /^\d*$/;
registerLocale("es", es); 
const validationSchema = yup.object({

	nombres: yup
    .string("Ingrese sus nombres")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Nombres requeridos"),
  apellidos: yup
    .string("Ingrese sus apellidos")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Apellidos requeridos"),
    /*
  _fecha_de_nacimiento: yup
    .string("Ingrese su fecha de nacimiento")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Fecha de nacimiento requerida"),*/
  genero: yup
    .string("Ingrese su g\u00E9nero")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("G\u00E9nero requerida"),      
  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email v\u00E1lido")
    .required("Email requerido"),
	celular: yup
    .string("Ingrese su n\u00FA de Celular")
    .matches(phoneRegExp,"Ingrese un n\u00FAmero de Celular v\u00E1lido")
    .min(7, 'Ingrese un n\u00FAmero de Celular v\u00E1lido')
    .max(8, 'Ingrese un n\u00FAmero de Celular v\u00E1lido')
    .required("N\u00FAmero de Celular requerido"),
  password: yup
    .string("Ingrese su contrase\u00F1a")
    .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
    
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+-.!@#\$%\^&\*])(?=.{8,})/,
      "Debe contener 8 Caracteres, al menos 1 May\u00FAscula, 1 Min\u00FAscula, 1 N\u00FAmero y 1 Caracter Especial (Ej. # $ % & !)"
    )
    .required("Contrase\u00F1a requerida"), 
    
 	/*
 	password2: yup
    .string("Ingrese su contrase\u00F1a")
    .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
    .required("Contrase\u00F1a requerida"),
  */
  password2: yup.string("Ingrese su contrase\u00F1a")
  	 .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
     .oneOf([yup.ref('password'), null], 'Las contrase\u00F1as no coinciden'),
	cedula_de_identidad: yup
		.string("Ingrese su C\u00E9dula de Itentidad")
		.matches(numberRegExp,"Ingrese una C\u00E9dula de Itentidad v\u00E1lida")
		.min(5, 'Ingrese una C\u00E9dula de Itentidad v\u00E1lida')
    .max(8, 'Ingrese una C\u00E9dula de Itentidad v\u00E1lida')  	
  	.required("su C\u00E9dula de Itentidad requerida"),
  ciudad: yup
  	.string("Ingrese el ciudad")
  	.required("Cuidad requerida"),
  extension: yup
  	.string("Ingrese la extensi\u00F3n")
  	.required("Extensi\u00F3n requerido"),	
	departamento: yup
  	.string("Ingrese el Departamento")
  	.required("Departamento requerido"),
  ciudad: yup
  	.string("Ingrese la ciudad")
  	.required("Cuidad requerida"),
  grado_academico: yup
  	.string("Ingrese una grado acad\u00E9mico")
  	.required("Grado acad\u00E9mico requerido"),
 /* idea: yup
 		.string()
    .nullable()
    .notRequired(),*/
});

const SignInDialog = (props) =>  {
  const { open, onClose,onOpenSignIn, onOpenRecoveryPassDialog } = props;
  const dispatch = useDispatch();
  
  const { processSuccess, failed, error, processMessage, processError,requesting } = useSelector(
    (state) => state.Login
  );
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
   
	const [fecha_de_nacimiento, setfecha_de_nacimiento] = React.useState(new Date( 2000,0,1 ) ) ;		
	const [captcha, setCaptcha] = React.useState(false) ;		
	
	const [captchaSuccess, setCaptchaSuccess] = React.useState(false) ;		
	
	
	const f = new Date()
	const recaptchaRef = React.useRef();
	const _yy = f.getFullYear();
	const _mm = (f.getMonth()+1 )<10? "0"+(f.getMonth()+1 ): (f.getMonth()+1 );
	const _dd = (f.getDate() )<10? "0"+(f.getDate()): (f.getDate() );
  const formik = useFormik({
    initialValues: {
      email: "",
      celular: "",
      password: "",
      password2: "",
      apellidos:"",
      nombres:"",
      role: "",
      genero: "M",
      //_fecha_de_nacimiento : _yy+"-"+_mm+"-"+_dd,
      
      departamento:        "BENI",
      ciudad:           "",
      grado_academico:  "NINGUNO", 
      //idea:       "",
      cedula_de_identidad: "",
      extension:"BE",
      
    },
    validationSchema: validationSchema,
    onSubmit: async(values) => {
    	 
    	var f = fecha_de_nacimiento			 
			var yy = f.getFullYear();
			var mm = (f.getMonth()+1 )<10? "0"+(f.getMonth()+1 ): (f.getMonth()+1 );
			var dd = (f.getDate() )<10? "0"+(f.getDate()): (f.getDate() );
			var hh = (f.getHours() )<10? "0"+(f.getHours() ): (f.getHours() );
			var mi = (f.getMinutes() )<10? "0"+(f.getMinutes() ): (f.getMinutes() );
			var se = (f.getSeconds() )<10? "0"+(f.getSeconds()): (f.getSeconds() );

    	var _fecha_de_nacimiento =  yy+"-"+mm+"-"+dd +" "+hh +":"+mi+":"+se;

    	values["fecha_de_nacimiento"] = _fecha_de_nacimiento
    	values["idea"] = ""
    //console.log("values", values)
      if (captchaSuccess){
      	dispatch(Actions.CreateUser(values));
      }else{ 
      	//dispatch(Actions.processFailed({error:"Debe verificar el captcha" }));
      }
    },
  });

	const onChangeCaptcha = (value) => {
	  if (value){
	  //	dispatch(Actions.clearMessages());	
	  	setCaptcha(true)	
	  }else{
	  	setCaptcha(false)
	  }
	  	
	  
	}
	
	/*
	const doSubmit = () => {
     let user_captcha_value = document.getElementById('user_captcha_input').value;
     if (validateCaptcha(user_captcha_value)==true) {
         alert('Captcha Matched');
     }
     else {
         alert('Captcha Does Not Match');
     }
	};
 */
	
  React.useEffect(() => {
    if (!requesting) {
      if (processSuccess) {
      	 formik.resetForm({})
      //  onClose();
      } 
    }
     // loadCaptchaEnginge(6); 
  }, [requesting, processSuccess, error,processMessage,processError, failed]);
/*
	let resetPresForm = {};// You will have to define this before useEffect

		const ResettingForm = () => {
		    const { resetForm } = useFormikContext();
		    resetPresForm = resetForm; // Store the value of resetForm in this variable
		    return null;
		};*/

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg"  fullScreen={fullScreen} >
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{ mt: 2, mb: 3 }}  spacing={1} >
            <Grid item lg={12}>
              <Typography
                variant="h5"
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  textTransform: "none",
                  marginBottom: 20
                }}
              >
                Registrarse
              </Typography>
            </Grid>
            
             <Grid  item lg={6} xs={12}   >
              <TextField
                label="Nombre(s)"
                placeholder="Ingrese sus nombres"
                fullWidth
                size="small"
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                error={formik.touched.nombres && Boolean(formik.errors.nombres)}
                helperText={formik.touched.nombres && formik.errors.nombres}
              />
            </Grid>
             <Grid item  lg={6} xs={12}   >
              <TextField
                label="Apellido(s)"
                placeholder="Ingrese sus Apellidos"
                fullWidth
                size="small"
                id="apellidos"
                name="apellidos"
                value={formik.values.apellidos}
                onChange={formik.handleChange}
                error={formik.touched.apellidos && Boolean(formik.errors.apellidos)}
                helperText={formik.touched.apellidos && formik.errors.apellidos}
              />
            </Grid> 
            
            <Grid item lg={6} xs={12} >
              <TextField
                label="N&uacute;mero de celular"
                placeholder="Ingrese su n&uacute;mero de celular"
                fullWidth
                size="small"
                id="celular"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                error={formik.touched.celular && Boolean(formik.errors.celular)}
                helperText={formik.touched.celular && formik.errors.celular}
              />
            </Grid>
            <Grid item lg={6} xs={12} >
              <TextField
                label="Correo electr&oacute;nico"
                placeholder="Ingrese su correo electr&oacute;nico"
                fullWidth
                size="small"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
             <Grid item lg={6} xs={12} >
              <TextField
                label="Contrase&ntilde;a"
                placeholder="Ingrese su Contrase&ntilde;a"
                fullWidth
                id="password"
                name="password"
                size="small"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
                    
            <Grid item lg={6} xs={12} >
              <TextField
                label="Repita Contrase&ntilde;a"
                placeholder="Repita su Contrase&ntilde;a"
                fullWidth
                id="password2"
                name="password2"
                size="small"
                type="password"
                value={formik.values.password2}
                onChange={formik.handleChange}
                error={
                  formik.touched.password2 && Boolean(formik.errors.password2)
                }
                helperText={formik.touched.password2 && formik.errors.password2}
              />
            </Grid>
             <Grid item lg={6} xs={12} style={{paddingTop: 0}} 	> 
				       <label style={{"max-width": "35%"}} className="custom-label"> Fecha de Nacimiento </label>
               <DatePicker
	              	showYearDropdown
	              	className="custom-datepicker"
	              	locale="es"
						      maxDate={(new Date())}
						      selected={fecha_de_nacimiento}
						      onChange={(date) => setfecha_de_nacimiento(date)}
						      dateFormat="dd/MM/yyyy"
						    />
            </Grid>
            
             <Grid item lg={4}  xs={8} >
              <TextField
                fullWidth
                size="small"
                label="Carnet de Identidad"
          			placeholder="Ingrese su Carnet de Identidad"
                error={formik.touched.cedula_de_identidad && Boolean(formik.errors.cedula_de_identidad)}
              	helperText={formik.touched.cedula_de_identidad && formik.errors.cedula_de_identidad}
                name="cedula_de_identidad"
                value={formik.values.cedula_de_identidad}
                id="cedula_de_identidad"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={2}  xs={4}  >
              <FormControl fullWidth>
		                    <InputLabel id="extension">Extensi&oacute;n</InputLabel>
								        <Select
								          labelId="extension"
								          id="extension"
								          name="extension"
								          size="small"
								          value={formik.values.extension}
								          label="Extensi&oacute;n"
								          error={formik.touched.extension && Boolean(formik.errors.extension)}
		                			helperText={formik.touched.extension && formik.errors.extension}
								          onChange={formik.handleChange}> 
								          <MenuItem value="BE"  > BE  </MenuItem>
													<MenuItem value="CB"  > CB  </MenuItem>
													<MenuItem value="LP"  > LP  </MenuItem>
													<MenuItem value="OR"  > OR  </MenuItem>
													<MenuItem value="PD"  > PD  </MenuItem>
													<MenuItem value="PT"  > PT  </MenuItem>
													<MenuItem value="SC"  > SC  </MenuItem>
													<MenuItem value="CH"  > CH  </MenuItem>
													<MenuItem value="TJ"  > TJ  </MenuItem>
		       							</Select>
	       							</FormControl> 
            </Grid>
                  
          
            <Grid item lg={6}  xs={12} >
               <FormControl fullWidth>
                <InputLabel id="genero">G&eacute;nero</InputLabel>
				        <Select
				          labelId="genero"
				          id="genero"
				          size="small"
				          name="genero"
				          style={{width:"100%"}}
				          value={formik.values.genero}
				          label="Categoria"
				          error={formik.touched.genero && Boolean(formik.errors.genero)}
            			helperText={formik.touched.genero && formik.errors.genero}
				          onChange={formik.handleChange}>
				          <MenuItem value={"M"}>Masculino</MenuItem>
				          <MenuItem value={"F"}>Femenino</MenuItem>
   							</Select>
 							</FormControl>
   				 </Grid>
   				 <Grid item lg={6}  xs={12} >
                
                <FormControl fullWidth>
                  <InputLabel id="grado_academico">Grado acad&eacute;mico</InputLabel>
					        <Select
					          labelId="grado_academico"
					          id="grado_academico"
					          name="grado_academico"
					          style={{width:"100%", height:42}}
					          value={formik.values.grado_academico}
					          label="Grado acad&eacute;mico"
					          error={formik.touched.grado_academico && Boolean(formik.errors.grado_academico)}
              			helperText={formik.touched.grado_academico && formik.errors.grado_academico}
					          onChange={formik.handleChange}>
					          
					         	  <MenuItem value={"NINGUNO"}>NINGUNO</MenuItem>
						          <MenuItem value={"NIVEL B&Aacute;SICO"}>NIVEL B&Aacute;SICO</MenuItem>
						          <MenuItem value={"NIVEL SECUNDARIO"}>NIVEL SECUNDARIO</MenuItem>
						          <MenuItem value={"BACHILLER"}>BACHILLER</MenuItem>
						          <MenuItem value={"T&Eacute;CNICO MEDIO"}>T&Eacute;CNICO MEDIO</MenuItem>
						          <MenuItem value={"T&Eacute;CNICO SUPERIOR"}>T&Eacute;CNICO SUPERIOR</MenuItem>
						          <MenuItem value={"LICENCIATURA"}>LICENCIATURA</MenuItem>
						          <MenuItem value={"INGENIER&Iacute;A"}>INGENIER&Iacute;A</MenuItem>
						          <MenuItem value={"MAESTR&Iacute;A"}>MAESTR&Iacute;A</MenuItem>
						          <MenuItem value={"DOCTORADO"}>DOCTORADO</MenuItem>
     							</Select>
   							</FormControl>
	       							
              </Grid> 
             <Grid item lg={6} xs={12}  >


										<FormControl fullWidth>
		                    <InputLabel id="departamento">Departamento</InputLabel>
								        <Select
								          labelId="departamento"
								          id="departamento"
								          name="departamento"
								          size="small"
								          value={formik.values.departamento}
								          label="Departamento"
								          error={formik.touched.departamento && Boolean(formik.errors.departamento)}
		                			helperText={formik.touched.departamento && formik.errors.departamento}
								          onChange={formik.handleChange}>
								        
								          <MenuItem value="BENI"        > BENI        </MenuItem>
													<MenuItem value="COCHABAMBA"  > COCHABAMBA  </MenuItem>
													<MenuItem value="CHUQUISACA"  > CHUQUISACA  </MenuItem>
													<MenuItem value="LA PAZ"      > LA PAZ      </MenuItem>
													<MenuItem value="ORURO"       > ORURO       </MenuItem>
													<MenuItem value="PANDO"       > PANDO       </MenuItem>
													<MenuItem value="POTOSI"      > POTOSI      </MenuItem>
													<MenuItem value="SANTA CRUZ"  > SANTA CRUZ  </MenuItem>
													<MenuItem value="TARIJA"      > TARIJA      </MenuItem>

								         
		       							</Select>
	       							</FormControl>
	       							
                     

                  </Grid>
                  <Grid item lg={6} xs={12} >
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Ingrese la ciudad"
                      label="Ciudad"
                      error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
		                	helperText={formik.touched.ciudad && formik.errors.ciudad}
                      name="ciudad"
                      value={formik.values.ciudad}
                      id="ciudad"
                      onChange={formik.handleChange}
                    />
                  </Grid>
             
               {/*
	            <Grid item lg={12} xs={12} >
	              <TextField
	                fullWidth
	                size="small"
	                placeholder="Idea del Emprendimiento"
	                label="Idea del Emprendimiento"
	                multiline={true}
	                rows={2}
	                error={formik.touched.idea && Boolean(formik.errors.idea)}
	              	helperText={formik.touched.idea && formik.errors.idea}
	                name="idea"
	                value={formik.values.idea}
	                id="idea"
	                onChange={formik.handleChange}
	              />
	            </Grid>
              */}
             <Grid
              item
              lg={12}
              sx={{
                mt: 1,
              }}
            >
            {processError? 
            	<div style={{ color:'red' }}>{processError}</div>
            :	"	"
            }
            {processMessage? 
            	<div style={{ color:'green' }}>{processMessage}</div>
            :	"	"
            }
            </Grid>
            <Grid
              item 
              lg={12}
              xs={12} 
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              
				      <div>
	           <Captcha
	            onChange={status => setCaptchaSuccess(status) }
	          />
       </div>
               
			       </Grid>
			       
			       <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
            
              <Button
                variant="text"
                size="small"
                onClick={onOpenRecoveryPassDialog}
                sx={{ textTransform: "none" }}
              >
                Olvid&oacute; su contrase&ntilde;a?
              </Button>
            </Grid>
            
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
              }}
            >
              <Button
                fullWidth
                size="small"
                type="submit"
                 
                style={{
                  textTransform: "none",
                  color: "#ffffff",
                  backgroundColor: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  borderRadius: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                Registrarse
              </Button>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: 14,
                }}
              >
              Ya tienes una cuenta?
              </Typography>
              <Button
                size="small"
                onClick={onOpenSignIn}
                style={{
                  textTransform: "none",
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  borderRadius: 15,
                }}
              >
                Ingresar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignInDialog;
