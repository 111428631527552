import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup"; 

import { 
	Container,
	Card, 
	Grid, 
	CardActions, 
	Link ,
	Divider, 
	InputLabel, 
	OutlinedInput, 
	Checkbox, 
	FormControlLabel, 
	FormControl, 
	CssBaseline, 
	Avatar, 
	CardContent , 	
	Button , 
	Box , 
	Typography, 
	Input, 
	InputAdornment,
	Paper,
	InputBase,
	IconButton,
	TextField,
	FormHelperText
	  } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { styled } from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import * as UserActions from "../../redux/actions/user.actions";


const FBButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor:  theme.palette.googleFacebookColor,
  '&:hover': {
    backgroundColor: theme.palette.googleFacebookColor,
  }, 
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const FixedButton = styled(Button)(({ theme }) => ({   
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const validationSchema = yup.object({
  
	email: yup
	.string("Ingrese su email")
	.email("Ingrese un correo electr\u00F3nico v\u00E1lido")
	.required("Correo electr\u00F3nico requerido"),
	password: yup
	.string("Ingrese su contrase\u00F1a")
	//.min(8, "Password should be of minimum 8 characters length")
	.required("Contrase\u00F1a requerida"),
	store_code: yup
    .string("Ingrese el código de su tienda")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Código tienda requerido"),
});

const LoginForm = props => { 
	const dispatch = useDispatch();
	const { defConfig, params, defLang, activeForm, requesting, processSuccess,  processError,  processMessage, usertoken , error } = useSelector((state) => state.Login);
	const [showPassword, setShowPassword] = React.useState(false)
	
	const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const formik = useFormik({
    initialValues: {
      "email":     "" , // "yam@mail.com",
      "password":  "" , //     "12234444",
	  "store_code":  "" ,// napoli
    },
    enableReinitialize:true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values, {setSubmitting, setErrors, setStatus, resetForm} ) => {
		console.log(values)
	  	dispatch(UserActions.login(values))
    },
  });
  const navigate = useNavigate();
  React.useEffect(()=>{
  	if (usertoken){
  		navigate("/dashboard");
  	}
  },[usertoken])

  return (
          <Box
            sx={{
              mt: { xs:0, md:0 , lg:2 },
              ml: { xs:0, md:0 , lg:2 },
              mr: { xs:0, md:0 , lg:2 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          > 
            <form onSubmit={formik.handleSubmit}>
            
            <Grid spacing={1} container   >
	          	
	          	<Grid item xs={12} sx={{ pl:0,  display:{xs:"block" } }}>
	          		<img style={{width: "100%"}}  src="/images/logo.png"/>
	          	</Grid>   
	          	<Grid item xs={12} sx={{mt:1, ml:{xs:1, md:0}, mr:{xs:1, md:0}}}>
	          		<Typography variant="h3"  sx={{mb:2}} fontWeight="bolder" color="primary">
				        	{defConfig[defLang].signin}
				        </Typography>
	          	 	<TextField
                      fullWidth
                     
                      label={defConfig[defLang].store_code}
                 
                      error={formik.touched.store_code && Boolean(formik.errors.store_code)}
		                	helperText={formik.touched.store_code && formik.errors.store_code}
                      name="store_code"
                      value={formik.values.store_code}
                      id="store_code"
                      onChange={formik.handleChange}
                      
                      
                    />
                
				        
							</Grid>

							<Grid item xs={12} sx={{mt:1, ml:{xs:1, md:0}, mr:{xs:1, md:0}}}>
	          		 
	          	 	<TextField
                      fullWidth
                     
                      label={defConfig[defLang].formemail}
                 
                      error={formik.touched.email && Boolean(formik.errors.email)}
		                	helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      value={formik.values.email}
                      id="email"
                      onChange={formik.handleChange}
                      
                      endAdornment={
				              <InputAdornment position="end">
				                <IconButton 
				                  edge="end"
				                >
				                	<MailOutlineIcon />
				                </IconButton>
				              </InputAdornment>
				            } 
                    />
                
				        
							</Grid>

							<Grid item xs={12} sx={{mt:0, ml:{xs:1, md:0}, mr:{xs:1, md:0}}}>
	              <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
				          <InputLabel htmlFor="password">{defConfig[defLang].formpassword}</InputLabel>
				          <OutlinedInput
				          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
				            
				            error={formik.touched.password && Boolean(formik.errors.password)}
	                	helperText={formik.touched.password && formik.errors.password}
                    name="password"
                    value={formik.values.password}
                    id="password"
                    onChange={formik.handleChange}
                      
				            type={showPassword ? 'text' : 'password'}
				            endAdornment={
				              <InputAdornment position="end">
				                <IconButton
				                  aria-label="toggle password visibility"
				                  onClick={handleClickShowPassword}
				                  onMouseDown={handleMouseDownPassword}
				                  edge="end"
				                >
				                  {showPassword ? <LockOpenIcon/> : <LockIcon />}
				                </IconButton>
				              </InputAdornment>
				            } 
				            label={defConfig[defLang].formpassword}
				      		/>
			            {!!formik.errors.password && (
								    <FormHelperText error id="accountId-error">
								      {formik.errors.password}
								    </FormHelperText>
								  )}
				        </FormControl>
				      </Grid>  
				      <Grid item xs={12} sx={{mt:0, ml:{xs:1, md:0}, mr:{xs:1, md:0}}}>
	            	<FixedButton
	                type="submit"
	                fullWidth
	                variant="contained"
	                sx={{ mt: 2, mb: 2, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"} , fontSize:{ xs :"0.9rem", md: "0.6285rem" }  }}
	            	>
	              	{defConfig[defLang].signin}
	             	</FixedButton>
	            	 
	            </Grid>
	           
	          </Grid>        
						<Grid container  sx={{ mt: 0, mb: 0}}>
	            <Grid item xs sx={{mt:0 , ml:{xs:1, md:0}, mr:{xs:1, md:0} }}>
	              <Link href="#" variant="body2" onClick={() => {dispatch(UserActions.activateForm("forgot")); }}>
	                {defConfig[defLang].forgotpassword}
	              </Link>
	            </Grid>
				
	          </Grid>
	        </form>
          </Box> 
        
		
  )
};

export default LoginForm;
