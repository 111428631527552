import actionTypes from "../action.types";
import {getUserRol,getCookie,setCookie,getUserInfo } from '../../utils/Functions';
import { toast } from "react-toastify";
import Lngs from "./languages.js";

var token  = getCookie(process.env.REACT_APP_NAME+'_usertoken')
var name = getCookie(process.env.REACT_APP_NAME+'_username')


var usertoken
var defLang = process.env.REACT_APP_DEF_LANGUAGE
if (token){
	if (token != "undefined"){		
		usertoken=token
	} 
}
var username
if (name){
	if (name.length>0){
		username = name;
	}	
}
var userInfo= getUserInfo()
var useracronym=""
var storepath=""
if (userInfo.USERID){
	useracronym = (userInfo.FIRSTNAME.substr(0,1)+ userInfo.LASTNAME.substr(0,1)).toUpperCase()
	if (userInfo.SUPERADMIN=="NO"){
		storepath   = (process.env.REACT_APP_PATH_IMAGES_MANAGER==="Wd"? "/":"" )+ "store_"+ (userInfo.ORGANIZATIONCODE)+"/"	
	}
} 
var menu= []
let dataMenu = localStorage.getItem(process.env.REACT_APP_NAME+'_menu')
if (dataMenu){
	if (dataMenu.length>0){
		menu = JSON.parse(dataMenu)
	}
}



const initialState = {
  requestingLogin: false,
  success: usertoken?true:false,
  failed: false,
  error: null, 
  showDashboard: usertoken?true:false,  
  defConfig: Lngs.defConfig,
  params :Lngs,
  activeForm: "login",
  defLang: defLang?defLang:process.env.REACT_APP_DEF_LANGUAGE,  
  role: getUserRol(),
  usertoken: usertoken,
  username: username,
  useracronym: useracronym,
  storepath:storepath,
  menu:menu,
  recoverymessage:"",
  reloadPage:false
};



const LoginReducer = (state = initialState, action) => {
	
  const { type, payload } = action;
	switch (type) {
  	case actionTypes.ACTIVE_FORM: 
  		return {
        ...state,
        requestingLogin: false,
        success: true,
				activeForm: payload
      };
  	
    case actionTypes.LOGIN_SUCCESS:
    	setCookie(process.env.REACT_APP_NAME+'_usertoken', action.payload.token,  process.env.REACT_APP_SESSION_DURATION);
    	setCookie(process.env.REACT_APP_NAME+'_username', action.payload.data.USERNAME, process.env.REACT_APP_SESSION_DURATION);
     
    	setCookie(process.env.REACT_APP_NAME+'_store_code', action.payload.data.ORGANIZATIONCODE, process.env.REACT_APP_SESSION_DURATION);

      toast.success("Welcome");	
     	//console.log(action.payload)
     	localStorage.setItem(process.env.REACT_APP_NAME+'_menu',  JSON.stringify(action.payload.menu))
     	
     	
      var useracronym=""
			var storepath=""
			useracronym = (action.payload.data.FIRSTNAME.substr(0,1)+ action.payload.data.LASTNAME.substr(0,1)).toUpperCase()
			if (action.payload.data.SUPERADMIN=="NO"){
				storepath   = (process.env.REACT_APP_PATH_IMAGES_MANAGER==="Wd"? "/":"" )+ "store_"+ (action.payload.data.ORGANIZATIONCODE)+"/"	
			}
			
      return {
        ...state,
        requestingLogin: false,
        success: true,
        username: action.payload.data.USERNAME,
        usertoken: action.payload.token,
        role:action.payload.data.role, 
        useracronym:useracronym,
        storepath:storepath,
        menu : action.payload.menu,
        reloadPage:true
      };
    case actionTypes.LOGIN_FAILED:
   		toast.error(payload.error);	
      return {
        ...state,
        requestingLogin: false,
        failed: true,
        success: false,
        error: payload.error,
      };
    
    case actionTypes.RECOVERY_SUCCESS:    	
    	toast.success(payload.message);	

    	setCookie(process.env.REACT_APP_NAME+'_recoverymail_'+payload.email , "SEND",  process.env.REACT_APP_SESSION_DURATION);
    	
      return {
        ...state,
        requestingLogin: false,
        success: true,
        recoverymessage:payload.message,
      };

    case actionTypes.RECOVERY_FAILED:
   		toast.error(payload.error);	
      return {
        ...state,
        requestingLogin: false,
        failed: true,
        success: false,
        error: payload.error,
      };
    
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
       	requestingLogin: true,
       	error:null
      };
    case actionTypes.USER_LOADED:
      return {
        ...state,
        requestingLogin: false,
        success: true,
        user: payload,
      };  
       
    case actionTypes.LOGOUT:
    	setCookie(process.env.REACT_APP_NAME+'_usertoken', null , 0.0001)
    	setCookie(process.env.REACT_APP_NAME+'_username', null , 0.0001)
      setCookie(process.env.REACT_APP_NAME+'_store_code', null , 0.0001)
    		localStorage.removeItem(process.env.REACT_APP_NAME+'_menu');
      return {
        ...state,
        username: "",
        usertoken: "",
        role: "",
        showDashboard: false,
      };
    default:
      return state;
  }
};

export default LoginReducer;
