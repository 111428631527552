import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  Radio,
  RadioGroup,
  DialogActions ,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Actions from "../../redux/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
const validationSchema = yup.object({

  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email v\u00E1lido")
    .required("Email requerido")
});

const SignInDialog = (props) => {
  const { open, onClose,onOpenSignIn } = props;
  const dispatch = useDispatch();
	const { processSuccess, failed, error, processMessage, processError,requesting } = useSelector(
    (state) => state.Login
  );

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
       dispatch(Actions.RecoveryPass(values));

    },
  });

  React.useEffect(() => {

    if (!requesting) {
      if (processSuccess) {
      	 formik.resetForm({})
      //  onClose();
      
      
      
      
      } 
    }
     // loadCaptchaEnginge(6); 
  }, [requesting, processSuccess, error,processMessage,processError, failed]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
     
    	<DialogActions>
        <Button onClick={onClose} startIcon=<CloseIcon  style={{ color: "#004282"}} /> > </Button>
      </DialogActions>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{ mt: 2, mb: 3 }} >
            <Grid item xs={12} lg={12} >
              <Typography
                variant="h5"
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  textTransform: "none",
                  marginBottom: 20
                }}
              >
                Recuperar Contrase&ntilde;a
              </Typography>
            </Grid> 
            <Grid item lg={12} xs={12} sx={{ mt: 1 }}>
              <TextField
                label="Correo electr&oacute;nico"
                placeholder="Ingrese su correo electr&oacute;nico"
                fullWidth
                size="small"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>  
            
            <Grid
	              item
	              lg={12} xs={12}
	              sx={{
	                mt: 1,
	                mb:1
	              }}
	            >
	            {processError? 
	            	<div style={{ color:'red' }}>{processError}</div>
	            :	"	"
	            }
	            {processMessage? 
	            	<div style={{ color:'green' }}>{processMessage}</div>
	            :	"	"
	            }
	          </Grid>
	            
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
              }}
            > 
	            	 <Grid
		              item
		              lg={12}
		              xs={12}
		              sx={{
		                mt: 1,
		              }}
		            >
		              <Button
		                fullWidth
		                size="small"
		                type="submit"
		                style={{
		                   	textTransform: "none",
			                  color: "#ffffff",
			                  backgroundColor: "#004282",
			                  fontFamily: "'Open Sans', sans-serif",
			                  fontWeight: "bolder",
			                  borderRadius: 20,
			                  paddingTop: 5,
			                  paddingBottom: 5,
		                }}
		              >
		                Recuperar
		              </Button>
	             	</Grid>   
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: 14,
                }}
              >
              Ya tienes una cuenta?
              </Typography>
              <Button
                size="small"
                onClick={onOpenSignIn}
                style={{
                  textTransform: "none",
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  borderRadius: 15,
                }}
              >
                Ingresar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignInDialog;
