import React from "react";
import {useNavigate} from 'react-router-dom';
import PropTypes from "prop-types";
import {
  Container,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Actions from "../../redux/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object({
  email: yup
    .string("Ingrese su correo electr&oacute;nico")
    .email("Ingrese un correo electr&oacute;nico v\u00E1lido")
    .required("Correo electr&oacute;nico requerido"),
  password: yup
    .string("Ingrese su contrase\u00F1a")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Contrase\u00F1a requerida"),
});

const SignInDialog = (props) => {
  const { open, onClose, onOpenCreateUser, onOpenRecoveryPassDialog } = props;
  const dispatch = useDispatch();
  const [resultMsg,setResultMsg]     = React.useState('');
  const { success, failed, error, requesting } = useSelector(
    (state) => state.Login
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      const data = { email: values.email, password: values.password };
      dispatch(Actions.login(data));
      
      navigate("/home");
      

    },
  });

  React.useEffect(() => {
    if (!requesting) {
      if (success) {
        onClose();
      }else{
      	setResultMsg(<div style={{ color:'red' }}>{error}</div>)
      }
    }
  }, [requesting, success, error, failed]);
/*
	let resetPresForm = {};// You will have to define this before useEffect

		const ResettingForm = () => {
		    const { resetForm } = useFormikContext();
		    resetPresForm = resetForm; // Store the value of resetForm in this variable
		    return null;
		};*/
	const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{ mt: 2, mb: 3 }} spacing={1} >
            <Grid item lg={12} xs={12}>
              <Typography
                variant="h5"
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  textTransform: "none",
                  marginBottom: 20
                }}
              >
                Ingresar
              </Typography>
            </Grid>
            <Grid item lg={12} xs={12} sx={{ mt: 1 }}>
              <TextField
                label="Correo electr&oacute;nico"
                placeholder="Ingrese su correo electr&oacute;nico"
                fullWidth
                size="small"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item lg={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                label="Contrase&ntilde;a"
                placeholder="Ingrese su Contrase&ntilde;a"
                fullWidth
                id="password"
                name="password"
                size="small"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
             
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                size="small"
                onClick={onOpenRecoveryPassDialog}
                sx={{ textTransform: "none" }}
              >
                Olvid&oacute; su contrase&ntilde;a?
              </Button>
            </Grid>
            
            <Grid container >
                {resultMsg}
            </Grid>
            
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
              }}
            >
              <Button
                fullWidth
                size="small"
                type="submit"
                style={{
                  textTransform: "none",
                  color: "#ffffff",
                  backgroundColor: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  borderRadius: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                Ingresar
              </Button>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                style={{
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: 14,
                }}
              >
              No tienes una cuenta?
              </Typography>
              <Button
                size="small"
                style={{
                  textTransform: "none",
                  color: "#004282",
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: "bolder",
                  borderRadius: 15,
                }}
                onClick={onOpenCreateUser}
              >
                Registrate
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignInDialog;
