import * as React from 'react';
import PropTypes from 'prop-types';
import {  Grid, Card,Typography,Button } from "@mui/material";
 
import {  getImagePath } from '../../utils/Functions';

const CompOrderItemContainer = (props) => {
    
  return (
  	 
			<Card sx={{ pt:1, pb:1}}>
				<Grid container px={1 }sx={{fontSize:12, fontWeight:"bold"}} sx={{fontSize:12, borderBottom: "1px solid", borderColor:"success.main",  mt:1}} >
  			 	<Grid item xs={1} >
          </Grid>
          <Grid item xs={2} >
	      	Estado
        	</Grid>	
					<Grid item xs={3} >
	      	Producto
        	</Grid>	 
          <Grid item xs={2} sx={{textAlign:"right"}} >
	      	Precio
        	</Grid>	 
        	<Grid item xs={2} sx={{textAlign:"right"}}  >
	      	Precio con descuento
        	</Grid>	 
        	<Grid item xs={1} sx={{textAlign:"right"}} >
	      	Cantidad
        	</Grid>	  
		      	
        </Grid>  
                
    		{props.field.value.map((item, index) => {
    			return(
        		
          			<Grid container sx={{fontSize:12, borderBottom: "1px solid", borderColor:"success.main",  mt:1}} >
          			 	<Grid item xs={1} px={1}>
        						<img 
													key={"imagepath"+index} 
													name={"imagepath_"+index}  
													style={{maxHeight: 250, width:"100%", cursor:"pointer" }}
													 
													src={getImagePath(item.IMAGEPATH)} 
													alt={item.IMAGEPATH}
										/> 
		              </Grid>
		              <Grid item xs={2} sx={{display:"flex", justifyContent: "left", alignItems:"center", alignContent:"center" }}>
					      	{item.STATUS} 
		            	</Grid>	
									<Grid item xs={3} sx={{display:"flex", justifyContent: "left", alignItems:"center", alignContent:"center" }}>
					      	{item.PRODUCTNAME+ " - "+ item.SKU} 
		            	</Grid>	 
			            <Grid item xs={2} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>
					      	{item.PRICE} 
		            	</Grid>	 
		            	<Grid item xs={2} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>
					      	{item.DISCOUNTPRICE} 
		            	</Grid>	 
	              	<Grid item xs={1} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>
					      	{item.QUANTITY} 
		            	</Grid>	  
                </Grid>  
              
	      	)
      	})}
   		</Card> 
		 
  );
};

CompOrderItemContainer.propTypes = {};

export default CompOrderItemContainer;

