import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import {
  Container,
  Typography,
  FormGroup,
  Grid,
  Card,
  Button, 
  IconButton,
  Popover ,
  TextField,
  Input,
  InputAdornment,
  Radio ,
  RadioGroup ,
  FormControlLabel,
  Dialog
} from "@mui/material";
import Loading from "../../components/Loading";
import FileManagerDialog from "./FileManagerDialog";
import { findObjName, find, getImagePath} from '../../utils/Functions';

import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComponentCardContainerDialog = (props) =>  {
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [isFaching,setIsFatching]    = useState(false)
	const [values,setValues]    = useState([])
	
	//for image manager
	const [openFileManagerDialog, setOpenFileManagerDialog] = React.useState(false);
	const [fieldName, setFieldName] = React.useState("");
	
  React.useEffect(()=>{
	 	 setValues(props.value)
  },[]) 
 
	
	const deleteItem = (event) => {
  	  	
  	let field = event.target.name.split("_")
  	let fieldid = field[0]
  	let _values = props.value
  	let pos = find(_values,  fieldid, "id" )
  	 
  	_values.splice(pos,1)
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  	
  }
	
  const addNewValue = (event) => {
   
  	let _values = props.value
  	let newId=0;
  	_values.map(function (key) {
  		if (newId< key.id){
  			newId=key.id
  		}
  	}); 
  	newId++
  	let newItem ={
  			id: newId,
		  	childs : [{
		  		id : 0, 
		  		title: "",
		  		susbtitle: "",
		  		body: "",
		  		button: "",
		  		buttonlink:"", 
		  		imagepath:""
		  	}]
		  }
  	_values.push(newItem)
  	let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  };
	
	const handleChange = (event) => {
		
		let field = event.target.name.split("_")
		let fieldid = field[0]
		let fieldsubid = field[1]
		let fieldname = field[2]
		let _values = props.value
		let pos = find(_values,  fieldid, "id" )
		let pos2 = find( _values[pos].childs,  fieldsubid, "id" )
		
		 _values[pos].childs[pos2][fieldname]= event.target.value
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
		
		props.handleChange(finalevent)
		
	};
  return (
    <Dialog fullWidth={true}  maxWidth="lg" scroll="paper" TransitionComponent={Transition} open={props.open} onClose={props.onClose} maxWidth="lg"  fullScreen={fullScreen} >
     	{isFaching ? <Loading />  : ""}
			<FileManagerDialog onSetFile={handleChange}  fieldName={fieldName} open={openFileManagerDialog}  onClose={()=>{ setOpenFileManagerDialog(false) } } />     	
      <Grid container sx={{ mt: 2, mb: 3 }}>
      	<Grid item xs={11} pl={3}  >
	              	<Typography variant="h5"
			            	 sx={{ 
		                  fontFamily: "'Open Sans', sans-serif",
		                  fontWeight: "bolder",
		                  textTransform: "none",

		                }}>
		               Crear Lista
			        </Typography>  
	    </Grid>
      	<Grid item xs={1} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
				<CloseIcon />
			</IconButton>
      	</Grid>
      	<Grid item xs={12} >
        	<Card>
          	<Container style={{ marginTop: 20, marginBottom: 20  }}>
            	<Grid container spacing={1}>
	            	{props.value!=null &&
	            		<> 
		            		{props.value.map((mastervalue, index) => {
		            			return(
		            		 		
				            		<Grid item xs={12}>
				            			<Card>
					            			<Grid container p={2} spacing={1}>
					            			
					            				{mastervalue.childs.map((value, index) => {
					            					return(
								            			<Grid   item lg={12/mastervalue.childs.length }>
								            				<Grid container   sx={{ pb:1, pr:1} } spacing={1}>
								            				 
									            				  <Grid item xs={12}>
												            			<TextField
													                     	key={"title"+index}
													                      fullWidth
													                      size="small"
													                      label="Titulo" 
													                      value={value.title}
													                      name={mastervalue.id+"_"+value.id+"_title"}
													                      onChange={handleChange}
													                    />
													              </Grid>
													              <Grid item xs={12}>
																      		<TextField
													                     	key={"subtitle"+index}
													                      fullWidth
													                      size="small"
													                      label="Subtitulo" 
													                      value={value.subtitle}
													                      name= {mastervalue.id+"_"+value.id+"_subtitle"}
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
													            	<Grid item xs={12}>
																      		<TextField
													                     	key={"body"+index}
													                      fullWidth
													                      size="small"
													                      label="Cuerpo" 
													                      value={value.body}
													                      name= {mastervalue.id+"_"+value.id+"_body"}
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
													            	<Grid item xs={12} lg={6}>
																      		<TextField
													                     	key={"button"+index}
													                      fullWidth
													                      size="small"
													                      label="Boton" 
													                      value={value.button}
													                      name={mastervalue.id+"_"+value.id+"_button"} 
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
													            	<Grid item xs={12} lg={6}>
																      		<TextField
													                     	key={"link"+index}
													                      fullWidth
													                      size="small"
													                      label="Link" 
													                      value={value.link}
													                      name={mastervalue.id+"_"+value.id+"_link"}  
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
													            
													            	<Grid item xs={12}>
												            			
																		{value.imagepath!=="" ?
 
																				<img 
																									key={"imagepath"+index} 
																									name={"imagepath_"+index}  
																									style={{maxHeight: 250, width:"100%", cursor:"pointer" }}
																									onClick={()=>{ setOpenFileManagerDialog(true);setFieldName(mastervalue.id+"_"+value.id+"_imagepath"); }}
																									src={getImagePath(value.imagepath)} 
																									alt={value.imagepath}
																						/>
																		:
																			<TextField
																				key={"imagepath"+index}
																				fullWidth
																				size="small"
																				value={value.imagepath}
																				name={mastervalue.id+"_"+value.id+"_imagepath"}  
																				disabled
																			/>
																		}
																		</Grid>
																	<Grid item xs={12} sx={{textAlign:"right", pr:1}}>
																		<Button
																			type="button"
																			variant="contained"
																			color="info"
																			onClick={()=>{ setOpenFileManagerDialog(true); setFieldName(mastervalue.id+"_"+value.id+"_imagepath"); }}
																			sx={{ mt: value.imagepath===""? "-79px": "-93px",  textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
																			> {value.imagepath!==""? "Cambiar Imagen": "Subir Imagen"}
																		</Button>
																		{value.imagepath!=="" &&
																			<Button
																				type="button"
																				variant="contained"
																				color="error"
																				key={"imagepath2"+index} 
																				
																				startIcon={<DeleteIcon  />}
																				onClick={()=> {handleChange({target: {name: mastervalue.id+"_"+value.id+"_imagepath" , value:""}} ) }}
																				sx={{ ml: 1, mt: value.imagepath===""? "-79px": "-93px", textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
																				> Quitar
																			</Button>
																		}
													              	</Grid>
													            
															</Grid>
										              </Grid>
									            	)
						      						})}  
									            
							              	<Grid  item lg={12} mr={1} sx={{textAlign: "right"}} >
							              		<Button
										                type="button"
										                variant="outlined"
										                color="error"
										                size="small"
										                name={mastervalue.id+"_split"}  
										                onClick={deleteItem}
										              	startIcon={<DeleteIcon  />}
										            	> Eliminar
										        </Button>
											</Grid>
												      	
						                </Grid>  
					                </Card> 
								      	</Grid>
							      	)
						      	})}
					      	</>
				      	}
            		<Grid item lg={12} sx={{textAlign: "right"}} >
				      		<Button
		                type="button"
		                variant="contained"
		                color="info"
		                onClick={  addNewValue }
		                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
		            	> Agregar
		            	</Button>
				      	</Grid>
            	
            	
	           	</Grid> 
						</Container>
          </Card>
        </Grid>   	
        <Grid item xs={12} sx={{textAlign: "right"}} pr={2} pt={2}  >
				      		<Button
		                type="button"
		                variant="contained"
		                color="success"
		                onClick={ props.onClose }
		                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
		            	> Aceptar
		            	</Button>
				      	</Grid>
      </Grid>
  </Dialog>
  );
};

ComponentCardContainerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ComponentCardContainerDialog;
