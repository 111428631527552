import "./assets/css/App.css";
import "./assets/css/main.css";
import "./assets/css/react-datepicker.css";

import { Provider } from "react-redux";
import store from "../src/redux/store";
import Route from "./Route";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Route />
      </Provider>
    </div>
  );
}

export default App;
