/* eslint-disable import/no-anonymous-default-export */
export default {
	
	//default labels
  defConfig: {
  	
  	"es-es": {
  			currency                     	:	"Bs."	,
			create                          :   "Creaci\u00F3n de ",
			update                          :   "Edici\u00F3n de ",



			warehouses :"Almancen",
			sliderbanners :"Banners rotatorios",
			categories:"Categor\u00EDas",
			cities:"Ciudades",
			cards:"Contenedores",
			forms:"Formularios",
			checkoutform:"Formularios de Registro de Compra",
			shippingform:"Formularios de Env\u00EDo",
			lists:"Listas",
			menu:"Men\u00FA",
			homepage:"P\u00E1gina de inicio",
			homestore:"P\u00E1gina de inicio de Tienda",
			orders:"Pedidos",
			products:"Productos",
			usergroups:"Roles",
			offices:"Sucursales",
			organizations:"Tiendas",
			users:"Usuarios",

			joinwaitlist                 	:	"Unirse a la lista de Espera"	,
			mob_joinwaitlist             	:	"lista de Espera"	,
			homelabel                    	:	"Inicio"	,
			company                      	:	"Compa\u00F1ia"	,
			contact                      	:	"Contacto"	,
			welcomeh1                    	:	"Bienvenido"	,
			recovery                      :	"Recuperar Contrase\u00F1a"	,
			recoverymessage               :	"Ingrese su correo electr\u00F3nico y recibir\u00E1 las indicaciones para recuperar su contrase\u00F1a"	,
			signup                       	:	"Registrarse"	,
			signupmessage                	:	"Cree una cuenta la misma sera evaluada y se le asignar\u00E1 un rol"	,
			agreeTermsRegister           	:	"I agree to the Privacy Policy and Terms of Use"	,
			signin                        : "Ingresar" ,
			signin2                      	:	"Ingresar"	,
			sendlabel                    	:	"Enviar"	,
			or                           	:	"O"	,
			formfirstname                   :	"Nombres"	,
			formlastname                 	:	"Apellidos"	,
			formphonenumber              	:	"Numero de Telefono"	,
			store_code                    :   "Tienda",
			formemail                    	:	"Correo electr\u00F3nico"	,
			formlogingoogle              	:	"Ingrese con Google"	,
			formloginfacebook            	:	"Ingrese con Facebook"	,
			formpassword                 	:	"Password"	,
			formpassword2                	:	"Repita el password"	,
			createaccount                	:	"No tienes una cuenta?"	,
			gootologin                   	:	"Tienes una cuenta?"	,
			forgotpassword               	:	"Olvido su password"	,
			myaccountlabel               	:	"Mi cuenta"	,
			logoutlabel                  	:	"Salir"	,
			editlabel                    	:	"Editar Registro"	,
			downloadtemplatelabel           :   "Descargar Plantilla",
			createlabel                  	:	"Crear Nuevo"	,
			bulkloallabel                 	:	"Carga Masiva"	,
			filterlabel                  	:	"Filtros"	,
			actionslabel                 	:	"Acciones"	,
			deleteItemlabel              	:	"Eliminar Registro"	,
			deletelabel                  	:	"Eliminar"	,
			checkstatusloadlabel            :   "Revisar el estado de mi carga",
			checklabel                  	:	"Revisar"	,
			cancellabel                  	:	"Cancelar"	,
			previewlabel                 	:	"Vista Previa"	,
			refreshlabel                 	:	"Refrescar"	,
			filterlabelsmessage          	:	"Filtro por etiqueta"	,
			novaluesmessage              	:	"No hay datos"	,
			deteledisclaimer             	:	"Esta seguro de eliminar este registro?"	,
			notloggedmessage1            	:	"Want to know more about your property?"	,
			notloggedmessage2            	:	"Sign-up today to learn more."	,
			savedatalabel                	:	"Guardar Datos"	,
			chooseimagelabel             	:	"Elegir Imagen"	,
			changeimagelabel             	:	"Cambiar Imagen"	,
			changepasslabel             	:	"Cambiar Contrase\u00F1a"	,
			changelabel             		:	"Cambiar"	,
			createfieldslabel            	:	"Crear Items"	,
			editfieldslabel              	:	"Administrar Items"	,
			createlistlabel              	:	"Crear Lista"	,
			editlistlabel                	:	"Editar Lista"	,
			createpolygonlabel            :	"Crear Poligono"	,
			editpolygonlabel             	:	"Editar Poligono"	,
			createitemslabel             	:	"Crear Elementos"	,
			edititemslabel               	:	"Editar Elementos"	,
			addfieldlabel                	:	"Agregar Campo"	,
			addcolumnlabel               	:	"Agregar Columna"	,
			savelabel                    	:	"Aceptar"	,
			fieldnamelabel               	:	"Nombre del Campo"	,
			fieldlabellabel              	:	"Etiqueta del Campo"	,
			fieldtypelabel               	:	"Tipo de Campo"	,
			colsizemobilelabel           	:	"Tama\u00F1o Mobile"	,
			colsizeweblabel              	:	"Tama\u00F1o Web"	,
			stylelabel                   	:	"Estilo"	,
			validationsabel              	:	"Validaciones"	,
			fontsizelabel                	:	"Tama\u00F1o del Texto"	,
			fontcolorlabel               	:	"Color"	,
			alertmessagelabel            	:	"Mensaje de Alerta"	,
			fieldrequiredlabel           	:	"Campo Requerido"	,
			justtextlabel                	:	"Solo Texto"	,
			justnumberlabel              	:	"Solo N\u00FAmero"	,
			justemaillabel               	:	"Solo email"	,
			minvaluelabel                	:	"Valor m\u00EDnimo"	,
			maxvaluelabel                	:	"Valor m\u00E1ximo"	,
			staticdatalabel              	:	"Datos Estaticos"	,
			dynamiclabel                 	:	"Datos din\u00E1micos"	,
			maxsizemobilemessage         	:	"En la versi\u00F3 movil solo se podra dividir hasta en 2 por fila"	,
			multimediamanagerlabel       	:	"Gestor Multimedia"	,
			pathlabel                    	:	"Ruta"	,
			searchinputlabel             	:	"Buscar Archivo"	,
			searchloadinputlabel            :   "Buscar por Código de Carga",
			searchlabel                  	:	"Buscar"	,
			previouslabel                	:	"Anterior"	,
			nextlabel                    	:	"Siguiente"	,
			testscriptlabel              	:	"Probar Script"	,
			loadformlabel                	:	"Carga Formulario"	,
			refreshingformlabel          	:	"Refrescando"	,
			optionslabel                 	:	"Opciones"	,
			urlapilabel                  	:	"url de la API"	,
			methodlabel                  	:	"Metodo"	,
			datatosendlabel              	:	"Datos a enviar"	,
			tokenlabel                   	:	"Token"	,
			labellabel                   	:	"Etiqueta"	,
			valuelabel                   	:	"Valor"	,
			fieldactionlabel             	:	"Accion"	,
			confirmlabel                  : "Confirmar",
			organizationlabel             : "Tienda",
			productlabel                  : "Producto",
			citylabel                     : "Ciudad",
			countrylabel                  : "Pais",
			latitudelabel                 : "Latitud",
			longitudelabel                : "Longitud",
			preloadpolygonlabel           : "Cargar Coordenadas",
			loadlabel                     : "Cargar",
			formatpolygonmessage          : "Formato [lat1],[lng1];[lat2],[lng2] Ej: -16.1111,-68.1111;16.1112,-68.1112 ",
			loadsuccessfulmessage         : "Cargados correctamente",
			invalidformatmessage          : "Datos inv\u00E1lidos",
			consoleLabel                  : "Administraci\u00F3n"
  	},
  	
  	"en-us": {
  		currency                     	:	"$"	,
			joinwaitlist                 	:	"Join Waitlist"	,
			mob_joinwaitlist             	:	"Join Waitlist"	,
			homelabel                    	:	"Home"	,
			company                      	:	"Company"	,
			contact                      	:	"Contact"	,
			welcomeh1                    	:	"Welcome"	,
			recovery                      :	"Recuperar Contrase\u00F1a"	,
			recoverymessage               :	"Ingrese su correo electr\u00F3nico y recibir\u00E1 las indicaciones para recuperar su contrase\u00F1a"	,
			signup                       	:	"Sign Up"	,
			signupmessage                	:	"Sign up to create an account and explore many things"	,
			agreeTermsRegister           	:	"I agree to the Privacy Policy and Terms of Use"	,
			signin             	          :	"Sign-in"	,
			signin2                      	:	"Sign-in"	,
			sendlabel                    	:	"Send"	,
			or                           	:	"OR"	,
			formfirstname                 :	"First Name"	,
			formlastname                 	:	"Last Name"	,
			formphonenumber              	:	"Phone Number"	,
			formemail                    	:	"Email Address"	,
			formlogingoogle              	:	"Sign up with Google"	,
			formloginfacebook            	:	"Sign up with Facebook"	,
			formpassword                 	:	"Password"	,
			formpassword2                	:	"Re-type password"	,
			createaccount                	:	"Don't you have an account?"	,
			gootologin                   	:	"Do you already have an account?"	,
			forgotpassword               	:	"Forgot password?"	,
			myaccountlabel               	:	"My Account"	,
			logoutlabel                  	:	"Logout"	,
			editlabel                    	:	"Edit Item"	,
			createlabel                  	:	"Create new"	,
			filterlabel                  	:	"Filters"	,
			actionslabel                 	:	"Actions"	,
			deleteItemlabel              	:	"Delete Item"	,
			deletelabel                  	:	"Delete"	,
			cancellabel                  	:	"Cancel"	,
			previewlabel                 	:	"Preview"	,
			refreshlabel                 	:	"Refresh"	,
			filterlabelsmessage          	:	"Filter labels"	,
			novaluesmessage              	:	"No labels"	,
			deteledisclaimer             	:	"Are you sure to delete this item"	,
			notloggedmessage1            	:	"Want to know more about your property?"	,
			notloggedmessage2            	:	"Sign-up today to learn more."	,
			savedatalabel                	:	"Save Data"	,
			chooseimagelabel             	:	"Choose Image"	,
			changeimagelabel             	:	"Change Image"	,
			createfieldslabel            	:	"Create Items"	,
			editfieldslabel              	:	"Manage Items"	,
			createlistlabel              	:	"Create List"	,
			editlistlabel                	:	"Update List"	,
			createpolygonlabel              :	"Create Polygon"	,
			editpolygonlabel             	:	"Update Polygon"	,
			createitemslabel             	:	"Create Items"	,
			edititemslabel               	:	"Update Items"	,
			addfieldlabel                	:	"Add Field"	,
			addcolumnlabel               	:	"Add Column"	,
			savelabel                    	:	"Save"	,
			fieldnamelabel               	:	"Field Name"	,
			fieldlabellabel              	:	"Label Name"	,
			fieldtypelabel               	:	"Field Type"	,
			colsizemobilelabel           	:	"Mobile Size"	,
			colsizeweblabel              	:	"Web Size"	,
			stylelabel                   	:	"Style"	,
			validationsabel              	:	"Validations"	,
			fontsizelabel                	:	"Font Size"	,
			fontcolorlabel               	:	"Color"	,
			alertmessagelabel            	:	"Alert Message"	,
			fieldrequiredlabel           	:	"Field Required"	,
			justtextlabel                	:	"Just Text"	,
			justnumberlabel              	:	"Just Number"	,
			justemaillabel               	:	"Just email"	,
			minvaluelabel                	:	"Min value"	,
			maxvaluelabel                	:	"Max value"	,
			staticdatalabel              	:	"Static Data"	,
			dynamiclabel                 	:	"Dynamic Data"	,
			maxsizemobilemessage         	:	"Mobile Version only accepts max 2 fields per Column"	,
			multimediamanagerlabel       	:	"Multimedia Manager"	,
			pathlabel                    	:	"Path"	,
			searchinputlabel             	:	"Search File"	,
			searchlabel                  	:	"Search"	,
			previouslabel                	:	"Previous"	,
			nextlabel                    	:	"Next"	,
			testscriptlabel              	:	"Test Script"	,
			loadformlabel                	:	"Load Form"	,
			refreshingformlabel          	:	"Refresing..."	,
			optionslabel                 	:	"Options"	,
			urlapilabel                  	:	"API url"	,
			methodlabel                  	:	"Method"	,
			datatosendlabel              	:	"Data to send"	,
			tokenlabel                   	:	"Token"	,
			labellabel                   	:	"Label"	,
			valuelabel                   	:	"value"	,
			fieldactionlabel             	:	"Action"	,
			confirmlabel                  : "Confirm",
			organizationlabel             : "Store",
			productlabel                  : "Product",
			citylabel                     : "City",
			countrylabel                  : "Country",
			latitudelabel                 : "Latitude",
			longitudelabel                : "Longitude",
			preloadpolygonlabel           : "Preload Coords",
			loadlabel                     : "Load",
			formatpolygonmessage          : "Format [lat1],[lng1];[lat2],[lng2] Sample: -16.1111,-68.1111;16.1112,-68.1112 ",
			loadsuccessfulmessage         : "Successfylly loaded",
			invalidformatmessage          : "Invalid format check your data",
			consoleLabel                  : "Admin"
  	}, 
  },
  conpanyInfo:process.env.REACT_APP_COMPANYNAME,
  //reference emal
  RefEmail: "mail@mail.com",
  
  //home header link items
  MenuLinks: {
  	"en-us": [
	   /* { mainLink: "Home", subLink: [], path: '/' },
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },    */
	  ],
  	"es-es": [
	    { mainLink: "Inicio", subLink: [], path: '/' },/*
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },  */  
	  ],
	},
	
	SideBarLinks: {
  	"en-us": [
	    { label: "Dashboard", subLink: [], path: '/dashboard' },
			{ label: "Project Planner", subLink: [], path: '/project-planer' },
	    { label: "Equity", subLink: [], path: '/blogs' },    
	    { label: "Renovation", subLink: [], path: '/blogs' },    
	    { label: "Resources", subLink: [], path: '/blogs' },    
	    { label: "Account Settings", subLink: [], path: '/blogs' },    
	    { label: "Log-off", subLink: [], path: '/blogs' },    
	  ],
  	"es-es": [
	    { label: "Dashboard", subLink: [], path: '/dashboard' },
			{ label: "Project Planner", subLink: [], path: '/project-planer' },
	    { label: "Equity", subLink: [], path: '/blogs' },    
	    { label: "Renovation", subLink: [], path: '/blogs' },    
	    { label: "Resources", subLink: [], path: '/blogs' },    
	    { label: "Account Settings", subLink: [], path: '/blogs' },    
	    { label: "Log-off", subLink: [], path: '/blogs' }, 
	  ],
	},
	
		//home header link items
  footerLinks: {
  	"en-us": [
	   { mainLink: "Terms Of Service",  path: '/home' },
			{ mainLink: "Privacy Policy", path: '/faqs' },    
	  ],
  	"es-es": [
	    { mainLink: "Terminos y Condiciones", path: '/home' },
			{ mainLink: "Politica de Privacidad", path: '/faqs' }, 
	  ],
	},
	 
	//home header steps
  footerSteps: {
  	"en-us": [
	  	"1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal \"Money Rates\" table (the \"Index\"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account." ,
			"2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing." ,  
	  ],
  	"es-es": [
  		"1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal \"Money Rates\" table (the \"Index\"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account." ,
	    "2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing." ,
			
	  ],
	},
	
	//home - list of services 
	Services:{
		"en-us":[
			{
			      icon: "./images/img1.PNG",
			      title: "Renovate my home",
			      button:"Discover More",
			      link:"/doc/bdp_lab.pdf",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			    {
			      icon: "./images/img2.PNG",
			      title: "Consolidate my debt",
			      link:"/doc/programa_aceleracion.pdf",
			      button:"Start Now",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			    {
			      icon: "./images/img3.PNG",
			      title: "Valuate my home",
			      link:"/doc/asistencia_tecnica.pdf",
			      button:"Start Today",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			   
			     
		],
		"es-es":[
			{
			      icon: "./images/img1.PNG",
			      title: "Renovate my home",
			      button:"Discover More",
			      link:"/doc/bdp_lab.pdf",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			    {
			      icon: "./images/img2.PNG",
			      title: "Consolidate my debt",
			      link:"/doc/programa_aceleracion.pdf",
			      button:"Start Now",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			    {
			      icon: "./images/img3.PNG",
			      title: "Valuate my home",
			      link:"/doc/asistencia_tecnica.pdf",
			      button:"Start Today",
			      description:
			        "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
			    },
			   
			     
		]
	}
  
};
