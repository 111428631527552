import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Footer from "../components/footer/Footer";
import MobileNav from "../components/header/MobileNav";
import {useNavigate} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const MainLayout = ({ openPanel, setOpenPanel }) => {
	const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
	const { usertoken,reloadPage } = useSelector(
    (state) => state.Login
  );
  const location = useLocation();
	const navigate = useNavigate();
  React.useEffect(()=>{
  	if (!usertoken){
  		navigate("/login");
  	}
    if(reloadPage){
      window.location.reload()
    }
  },[usertoken,reloadPage])
  const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (<>
     <Box sx={{ display: 'flex', background: location.pathname==="/login"? fullScreen?"fff": "#eceff3": "#eceff3" }}>
      <Header setOpenPanel={setOpenPanel} fullScreen={fullScreen} />
      <Box component="main" sx={{ display:"flex", justifyContent:"center", marginTop: fullScreen?0:"1rem", height:"calc(100vh - 16px)", marginRight:fullScreen?0:0, width: location.pathname==="/login" || fullScreen ?"100%": '100%', minHeight:windowHeight-40 /*height: 768, overflowY: "scroll"*/ }} >
	      <Outlet />      	
      </Box>
    </Box>
    
    </>
  );
};

MainLayout.propTypes = {};

export default MainLayout;